import { createElement } from 'react';
import logo from './logo_SSC.png'

const LogoSSC = () => {
    return (
        createElement('a', { href: "/service-center", tabIndex: 0, className: "logo-SSC", "aria-label": "Logo SSC, haga clic para redirigir a la página del Service Center" },
            createElement('img', { src: logo, alt: "Logo SSC" })
        )
    )
}

export default LogoSSC;