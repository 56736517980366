import React, {  useState, createElement } from 'react';
import { formatMoney, disconnect } from '../../utils';
import { addToCart, fetchCartItems, updateCart } from '../../services/cart-api';
import * as CartActions from '../../store/actions/cart'
import { connect } from 'react-redux';
import { setItemSession, getItemSession } from '../../utils';
import Modal from "react-bootstrap/Modal";
import { FaTimes } from 'react-icons/fa';
import { registrarAviseMe, validarAviseMe } from '../../services/contact-api';
import { fetchBanners } from "../../services/products-api";


// Config carousel
import OwlCarousel from 'react-owl-carousel';

const options = {
    items: 1,
    nav: true,
    navText: ['', ''],
    navClass: ['nav-btn prev-slide p-s', 'nav-btn next-slide n-s'],
    smartSpeed: 1000,
};


const ProductCard = ({ product, origin, refreshCartFn }) => {
    const [quantity, setQuantity] = useState(0);
    const [adding, setAdding] = useState(false);
    var [email, setEmail] = useState('');

    const decrement = () => setQuantity(q => isNaN(q) || q < 2 ? 0 : q - 1);
    const increment = () => setQuantity(q => q >= 0 ? q + 1 : 1);

    // ZOOM
    const [banners, setBanners] = useState([]);



    // Modificación
    const test_mod = (product) => {
        setIsOpenZoom(true);
        fetchBanners(product.idProduto)
            .then(result => {
                setBanners(result.data.Data.Dados);
            })
            .catch(reject => {
                console.log(reject, 'fetchbanners')
            })
    }

    const login = JSON.parse(getItemSession('_dados'));

    const handleChange = (e) => {
        if (parseInt(e.target.value) > 0) {
            setQuantity(parseInt(e.target.value));
        } else {
            setQuantity();
        }
    }

    const handleAddToCart = (e) => {
        e.preventDefault();

        const intQuantity = parseInt(quantity);

        if (isNaN(intQuantity) || intQuantity < 1) {
            alert("Inserta una cantidad valida para el producto.");
            return false;
        }

        setAdding(true);

        buttonActionDisabled(e);

        const get = getItemSession('_carrinho');
        const cartItems = JSON.parse(get);

        const item = cartItems?.Dados.filter(item => { return item.idProduto === product.idProduto })

        let time = 2000;

        if (item?.length > 0) {
            const newQuantity = intQuantity + parseInt(item[0].Quantidade)

            updateCart(product.idProduto, item[0].Item, newQuantity)
                .then(resultUpdate => {
                    if (resultUpdate.data.Codigo === 500) {
                        alert(resultUpdate.data.Status);
                        setAdding(false);
                        time = 0;
                        buttonActionEnabled(e);
                    } else {
                        fetchCartItems()
                            .then(result => {
                                if (result.data.Data.Dados.length > 0) {
                                    setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                    refreshCartFn(result.data.Data)
                                }
                                buttonActionEnabled(e);
                                setAdding(false);
                            })
                            .catch(reject => {
                                disconnect();
                                buttonActionEnabled(e);
                                setAdding(false);
                            })
                    }

                })
                .catch(reject => {
                    disconnect();
                    buttonActionEnabled(e);
                })
        } else {
            addToCart(product.idProduto, intQuantity, origin)
                .then(resultAdd => {
                    if (resultAdd.data.Codigo === 500) {
                        if (resultAdd.data.CodigoInterno === 4) {
                            disconnect()
                        } else {
                            alert(resultAdd.data.Status);
                            setAdding(false);
                            time = 0;
                        }
                        buttonActionEnabled(e);
                    } else {
                        setTimeout(async () => {
                            fetchCartItems()
                                .then(result => {
                                    if (result.data.Data.Dados.length > 0) {
                                        setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                        refreshCartFn(result.data.Data)
                                    }
                                })
                                .then(result => {
                                    buttonActionEnabled(e);
                                    setAdding(false);
                                })
                                .catch(reject => {
                                    disconnect();
                                    buttonActionEnabled(e);
                                    setAdding(false);
                                })
                        }, 1000);
                    }
                })
                .catch(reject => {
                    disconnect();
                    buttonActionEnabled(e);
                })
        }

    }


    const buttonActionDisabled = (e) => {
        e.persist();
        e.target.disabled = true;
    }
    const buttonActionEnabled = (e) => {
        e.persist();
        e.target.disabled = false;
    }

    // modal
    const [isOpen, setIsOpen] = useState(false);

    const showModal = () => {
        validarAviseMe()
            .then(resultAv => {
                var l = resultAv.data.Data.Dados
                if (l[0] !== null) {
                    setEmail(l[0].Email)
                    setIsOpen(true)
                }


            }).catch(reject => {
                setIsOpen(true)
            })


    };

    const hideModal = () => {
        setIsOpen(false);
    };

    // Modal zoom

    const [isOpenZoom, setIsOpenZoom] = useState(false);



    const hideModalZoom = () => {
        setIsOpenZoom(false);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);

    }

    const handleEmailSubmit = (e) => {
        e.preventDefault();

        let validEmailRe = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        if (validEmailRe.test(email)) {
            registrarAviseMe(product.idProduto, email)
                .then(() => {
                    alert('¡Correo electrónico registrado correctamente! ¡Se le notificará por ' + email + ' a partir de ahora!');
                    hideModal();
                })
                .catch(() => {
                    alert('Se produjo un error al enviar el mensaje. ¡Inténtalo de nuevo!');
                });
        } else {
            alert('¡Introduzca un correo electrónico válido!')
        }
    }

    return (
        <div className="card-item">
            <Modal
                show={isOpenZoom}
                className="modal modal-boleto"
                onHide={hideModalZoom}
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                size="lg"
            >
                <Modal.Header>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModalZoom}>
                        <FaTimes color="#00aeef" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="pt-3">
                        {banners.length > 0 && <OwlCarousel
                            className="owl-theme"
                            {...options}
                        >
                            {banners.map((banner, index) => (
                                <img src={banner.Link} alt="" key={index} className="image" />
                            ))}
                        </OwlCarousel>
                        }
                        {
                            banners.length === 0 && <div className='col-12 text-center'>
                                <i class='fas fa-spinner fa-spin fa-3x' style={{ margin: '0 auto' }}></i>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <div className="card-item--header">
                {product.Promocao === 1 && <span className="tag--card-item tag-promocao">Promoción</span> }
                {product.Lancamento === true && <span className="tag--card-item">Novedad</span>}

                <section className="section4">
                    <img src={product.LinkImagemLista} alt="" className="img-card-produto image" />
                    <button className="btn btn-sm btn-blck btn-zoom" img={product.LinkImagemLista} onClick={() => test_mod(product)} style={{ position: 'absolute', top: '40%', right: '45%' }} >
                        <i className="fas fa-eye" style={{ color: '#298ed1' }} ></i>
                    </button>
                </section>
            </div>

            <div className="card-item--body">
                {<p className="card-item--codigo">Código: {product.CodigoProduto.substring(3)}</p>}
                {<p className="card-item--codigo">PN: {product.EAN}</p>}
                <h5 className="card-item--titulo">
                    {createElement('a', { href: `/produto/${product.idProduto}` }, product.Descricao)}
                </h5>
                {!login.ApenasVisualizacao &&

                    <div className="produtos-detalhe--info quantidade-card">
                        <div className="misc ">
                            <p>Cantidad caja</p>
                            <p>{product.UnidadesPorCaixa}</p>
                        </div>
                    </div>
                }
                {login.ApenasVisualizacao &&
                    <h6 className="card-item--codigo">
                        Precio Publico Sugerido
                    </h6>
                }
                {product.PossuiPrecoPromocional
                    ?
                    <>
                        <p className="card-item--valor--de"><strike>$ {formatMoney(product.PrecoUnitario, 2, '.', ',')}</strike></p>
                        <p className="card-item--valor--por">$ {formatMoney(product.PrecoPromocional, 2, '.', ',')} </p>
                    </>
                    :
                    <>
                        {!login.ApenasVisualizacao ?
                            <>
                                <p className="card-item--valor">$ {formatMoney(product.PrecoUnitario, 2, '.', ',')}</p>
                            </>
                            :
                            <>
                                <p className="card-item--valor">$ {formatMoney(product.ValorTabela, 2, '.', ',')}</p>
                            </>
                        }
                    </>
                }
                {product.Estoque > 0 ? '' : !login.ApenasVisualizacao &&
                    <div>
                        <p className="card-item--estoque"><span className="no-stock">Producto no disponible</span></p>
                        <button
                            type="button"
                            name="btn-avise"
                            className="avise--btn btn border rouded-0"
                            onClick={showModal}
                        >Notificarme</button>
                        <Modal
                            show={isOpen}
                            className="modal modal-boleto"
                            onHide={hideModal}
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                        >
                            <Modal.Header>
                                <div className="modal-header_container">
                                    <h2 className="titulo h2">Notificarme sobre producto no disponible</h2>
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                                    <FaTimes color="#00aeef" />
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="pt-3">
                                    <form className="input-group">
                                        <input
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={handleEmail}
                                            className="form-control py-0"
                                            placeholder='Ingresar Email'
                                        />
                                        <button type="submit" className="btn form-control col-md-3" style={{ backgroundColor: '#00aeef', color: 'white' }} onClick={handleEmailSubmit}>
                                            Notificarme
                                        </button>
                                    </form>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                }
            </div>
            {product.Estoque > 0 && !login.ApenasVisualizacao &&
                <div className="card-item--footer">
                    <form className="form-adicionar">
                        <button
                            type="button"
                            name="btn-item"
                            className="btn form-adicionar--btn"
                            onClick={decrement}>-</button>
                        <input
                            type="number"
                            name="quantity"
                            value={quantity}
                            className="card-item--counter"
                            onChange={handleChange}
                            maxLength={4}
                        />
                        <button
                            type="button"
                            name="btn-item"
                            className="btn form-adicionar--btn"
                            onClick={increment}>+</button>
                    </form>
                    <button
                        type="button"
                        onClick={handleAddToCart}
                        className={`_button ${adding && 'active'}`}>
                        {adding
                            ? <><div className="spinner-border spinner-border-sm text-light"></div> Añadiendo</>
                            : 'Agregar'
                        }

                    </button>
                </div>
            }
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(ProductCard);
