import React from 'react';
import './homolog.css';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsappFooter = props => {
    let { flag } = props;

    if (flag !== "true") {
        return (
            <div>
                <button onClick={() => window.open('https://wa.me/5214425820239', '_blank')} className="whatsapp-btn">
                    <FaWhatsapp />
                </button>

                <div className="whatsapp-envia-mensagem">
                    <p>Enviar mensaje</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <button onClick={() => window.open('https://wa.me/5214425820239', '_blank')} className="whatsapp-btn whatsapp-btn-homolog">
                <FaWhatsapp />
            </button>

            <div className="whatsapp-envia-mensagem">
                <p>Enviar mensaje</p>
            </div>
        </div>
    )
}

export default WhatsappFooter;