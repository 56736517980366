import React, { useEffect, useRef, useState } from 'react';
import { fetchManufacturers } from '../services/manufacturers-api';
import { fetchCategories, fetchCategoriesById } from '../services/categories-api';
import { fetchVersion, fetchHomolog } from '../services/system-api';
import { fetchCartItems } from '../services/cart-api';
import { setItemSession, getItemSession } from '../utils';
import { fetchFiliais, fetchMudarFilialToken } from '../services/client-api';
import '../scss/loginFilial.scss';
import { useCookies } from 'react-cookie';
import LogoBike from '../img/shimano_bike.png';
import LogoFish from '../img/shimano_fish.png';
import LogoSsc from '../img/logo_SSC.png';

const LoginFilial = (props) => {
    var token = getItemSession('_token')

    const [spinnerLoad, setSpinnerLoad] = useState(false);
    const [filiais, setFiliais] = useState([]);
    const [filial1, setFilialLogin1] = useState('');
    const [filial2, setFilialLogin2] = useState('');
    const [cookies, setCookie] = useCookies([]);
    const latestProps = useRef(props);


    useEffect(() => { latestProps.current = props; });
    useEffect(() => {
        var token = getItemSession('_token')
        fetchFiliais(token).then(result => {
            if (result?.data?.Data?.Dados.length > 0) {
                setFiliais(result.data.Data.Dados);
            }
        })
    }, [])

    useEffect(() => {
        const loginAutomatico = () => {
            if (cookies._filialSelecionada) {
                let filialSelecionada = (cookies._filialSelecionada).toString();
                if (filialSelecionada) {
                    loadData(filialSelecionada);
                }
            }
        }
        if (filiais.length > 0) {

            let f1 = filiais[0].idFilial.toString();
            let f2 = filiais[1].idFilial.toString();
            setFilialLogin1(f1); //2 => Ciclismo
            setFilialLogin2(f2); //6 => Pesca
            loginAutomatico();

        }
    }, [filiais])



    const filialBike = async () => {
        loadData(filial1);
        setItemSession('_linkInfo', 'https://bike.shimano.com/es-AR');
    }

    const filialFishing = async () => {
        loadData(filial2);
        setItemSession('_linkInfo', 'https://fish.shimano.com/es-AR');
    }

    const loadData = (idFilial) => {
        setSpinnerLoad(true)
        var token = getItemSession('_token')
        fetchMudarFilialToken(idFilial, token).then(r => {
            setItemSession('_filialSelecionada', idFilial);

            const novoToken = r.data.Data;
            if (novoToken != null) {
                token = novoToken;
                setItemSession('_token', token);
            }

            Promise.all([
                fetchManufacturers(token),
                fetchCategories(token),
                fetchVersion(token),
                fetchHomolog(token),
                fetchCartItems(token),
                fetchCategoriesById(token, 2, true, true)
            ])
                .then(resultFetch => {
                    setItemSession('_fornecedores', JSON.stringify(resultFetch[0].data.Data.Dados));
                    setItemSession('_categorias', JSON.stringify(resultFetch[1].data.Data.Dados))
                    setItemSession('blink_versao', JSON.stringify(resultFetch[2].data.Data))
                    setItemSession('blink_homolog', JSON.stringify(resultFetch[3].data.Data))
                    setItemSession('_carrinho', JSON.stringify(resultFetch[4].data.Data))
                    setItemSession('_marcas', JSON.stringify(resultFetch[5].data.Data.Dados))
                    setSpinnerLoad(false)

                    setCookie('_filialSelecionada', idFilial, { path: '/', expires: (new Date(Date.now())), maxAge: 3650 });
                    window.location.href = process.env.REACT_APP_BASE_URL
                })
        })
    }

    return (
        <>
            <div className='container-topo'>
                <a className='btn-login' href='/logout'>LOGIN</a>
                <div className='container-topo-rigth'>
                    <a href='https://bike.shimano.com/es-AR/home.html' target="_blank" rel="noopener noreferrer" className='btn-corporativo'>SHIMANO </a>
                    <a className='btn-ssc' href='/service-center'>
                        <img alt='Logo' src={LogoSsc}></img>
                    </a>
                </div>
            </div>


            <section className='section-login-filial'>
                <div className='left-column' id="left_column">
                    <div className='bg_left'>
                        <span className='span-filial'>BICI</span>
                    </div>
                    <div>
                        <button className="logo_bici" onClick={filialBike}>
                            <img alt='Logo' src={LogoBike}></img>
                        </button>
                    </div>
                </div>

                <div className='right-column' id="right_column">
                    <div className='bg_right'>
                        <span className='span-filial'>PESCA</span>
                    </div>
                    <div>
                        <button className="logo_fish" onClick={filialFishing}>
                            <img alt='Logo' src={LogoFish}></img>
                        </button>
                    </div>
                </div>
            </section>

        </>

    );
}

export default LoginFilial;