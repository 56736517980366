import React, { useState, useEffect } from 'react';
import { filtersLimitWord, arrayStringToArrayInt, clearSubCategorias } from '../../utils';
import { getAllBrands, getAllCategories } from '../../containers/CategoriesContainer';
import { Loader } from '../Loader/Loader';

const Brands = () => {
    const [brand, setBrands] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const url = '/busca';
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const termo = params.get('t') || '';
    const fornecedores = params.get('f') || '';
    const brands = params.get('b') || '';
    let arrBrands = brands ? arrayStringToArrayInt(brands) : [];
    const categorias = params.get('c') || '';
    let arrCategorias = categorias ? arrayStringToArrayInt(categorias) : [];
    const subCategorias = params.get('s') || '';
    let arrSubCategorias = subCategorias ? arrayStringToArrayInt(subCategorias) : [];
    const page = params.get('p') || '';
    arrSubCategorias = clearSubCategorias(arrSubCategorias, arrCategorias, getAllCategories(0));

    const handleCheck = (e) => {
        e.preventDefault();

        if (!e.target.checked) {
            arrBrands = arrBrands.filter(arr => { return arr != e.target.value })
        } else {
            arrBrands.push(parseInt(e.target.value))
        }

        window.location.href = url
            + '?t=' + termo
            + '&c=' + arrCategorias.toString()
            + '&s=' + arrSubCategorias.toString()
            + '&f=' + fornecedores
            + '&b=' + arrBrands.toString()
            + '&p=' + page;
    }

    useEffect(() => {
        const allBrands = getAllBrands(0);
        const groups = allBrands.filter(function (newarr) {
            return newarr.idClassificacaoNivelAnterior == null;
        });
        setBrands(groups);
        setLoading(false);
    }, [])


   const clearURL = () => {
        window.history.pushState({}, '', '/busca?t=');
        localStorage.removeItem('visibleBrands');
        arrBrands = []; // Limpar a marca selecionada
        localStorage.removeItem('selectedBrand');
    };
    return (
        <>
            <form className="form-sticky">
                <div className="d-flex flex-row bd-highlight mb-3">
                    <h6 className="titulo h6">Marcas</h6>
                    <a href="/busca?t=" className="limpar-filtro" onClick={clearURL}>
                        Limpar filtro
                    </a>
                </div>
                {!isLoading ? (
                    brand.map((brand, index) => (
                        <fieldset key={`field-${index}`} className="form-group">
                            <input 
                                type="checkbox" 
                                name={`cat-${brand.idClassificacaoNivel}`} 
                                value={brand.idClassificacaoNivel} 
                                id={`filter-${brand.idClassificacaoNivel}`} 
                                className="checkbox" 
                                onChange={handleCheck} 
                                checked={arrBrands.find(v => v === brand.idClassificacaoNivel)} 
                            />
                            <label htmlFor={`filter-${brand.idClassificacaoNivel}`} title={brand.Nome}>{filtersLimitWord(brand.Descricao)}</label>
                        </fieldset>
                    ))
                ) : (
                        <Loader short="true" />
                    )}
            </form>
            
        </>
    )
}
export default Brands;