import React, { useState, useEffect, createElement, useRef } from 'react';
import { fetchAllPendencies } from '../services/pendencies-api';
import { formatMoney, formatDateToView, formatDateToDatabase, scrollToTop } from '../utils';
import { Loader } from '../components/Loader/Loader';
import * as CartActions from '../store/actions/cart'
import { connect } from 'react-redux';
import { FaCalendar } from 'react-icons/fa'

const PendenciesDetail = ({ refreshCartFn }) => {
    const [isLoading, setLoading] = useState(true);
    const [qtd, setQtd] = useState(0);
    const [pendencies, setPendencies] = useState([]);

    const [inputs, setInputs] = useState({
        NumeroDocumento: ''
    });   

    useEffect(() => {
        scrollToTop();
    });

   const fetchLocalPendencies = (NumeroDocumento = null, DataInicial = null, DataFinal = null, ApenasPagos = null,  ApenasVencidos = null) => {
        setLoading(true);

        fetchAllPendencies(NumeroDocumento, DataInicial, DataFinal, ApenasPagos, ApenasVencidos)
            .then(result => {
                setPendencies(result.data.Data.Dados)
                setQtd(result.data.Data.QuantidadeRegistrosTotal)
                setLoading(false)
                
            })
            .catch(reject => {
                console.log(reject)
                //disconnect();
            })
    }

  

    useEffect(() => {
        const currentDate = new Date();
        const initialDate = new Date(currentDate - 90 * 24 * 60 * 60 * 1000); // 90 dias    
        const  dataInicial = (initialDate.toISOString().split('T')[0]);
        const dataFinal=(currentDate.toISOString().split('T')[0]);
    
        fetchAllPendencies(null, dataInicial, dataFinal, null, null)
            .then(result => {
                setPendencies(result.data.Data.Dados)
                setQtd(result.data.Data.QuantidadeRegistrosTotal)
                setLoading(false)
                
            })
            .catch(reject => {
                console.log(reject)
                //disconnect();
            })
    }, []);   

    const inputDate = useRef(null);

    const onInputChange = event => {
        const { name, value } = event.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let inputValue = inputDate.current.value;
        let dates = inputValue.split(" a ");

        const DataInicial = formatDateToDatabase(dates[0]);
        const DataFinal = formatDateToDatabase(dates[1]);
      
        fetchLocalPendencies(inputs.NumeroDocumento, DataInicial, DataFinal);
    }

    return (
        <>
            
            <section className="pedidos container">
                <div className="sect-header">
                    <div className="sect-titulo justify-content-between">
                        <h2 className="titulo h2">Pendientes</h2>
                        <h6 className="titulo h6">Artículos: <span className="arial-bold">{qtd}</span></h6>
                    </div>
                    <hr />
                </div>

                <h4 className="titulo h4">Buscar</h4>
                <form className="form-pedidos" onSubmit={handleSubmit}>
                    <fieldset className="form-pedidos--wrapper">
                        <label className="input-label" htmlFor="pedido-numero">Número de documento</label>
                        <input
                            type="text"
                            className="input h6 mb-0 pt-3 pb-3"
                            placeholder="Ingresa número de pedido"
                            name="NumeroDocumento"
                            onChange={onInputChange}
                        />
                    </fieldset>
                    <fieldset className="form-pedidos--wrapper">
                        <label className="input-label" htmlFor="pedido-periodo">Fecha de emisión</label>
                        <label htmlFor="pedido-periodo" className="historico label-daterangepicker">
                            <FaCalendar className="h6 mr-2 mb-0" color="dodgerblue"/>
                            <span className="h6 mb-0">De</span>
                            <input
                                ref={inputDate}
                                type="text"
                                className="input-daterangepicker h6 mb-0"
                                name="daterange"
                                id="pedido-periodo"
                                readOnly="True"
                            />
                        </label>
                    </fieldset>
                    
                    <button type="submit" className="btn btn--lg btn--cinza btn--full">Filtrar</button>
                    
                </form>

                <h2 className="titulo h2 pedidos-titulo">Pendencies</h2>
                <div className="tabela-overflow">
                    {!isLoading ? (
                        pendencies.length > 0 ? (

                            <table className="tabela tabela-listrada">
                                <thead>
                                    <tr>
                                        <th width="110">Número de Documento</th>
                                        <th width="160">Fecha Emisión</th>
                                        <th width="160">Fecha Vencimiento</th>
                                        <th width="200">Fecha de Pago</th>
                                        <th width="200">Llave Bco.</th>
                                        <th width="175">Balance de Cuenta</th>                                     
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {pendencies.map((invoice, index) =>
                                        <tr key={index}>
                                            <td><span>{invoice.NumeroDocumento}</span></td>
                                            <td>{formatDateToView(invoice.DataEmissao)}</td>
                                            <td>{formatDateToView(invoice.DataVencimento)}</td>
                                            <td>{!invoice.DataPagamento
                                                    ? 'Piendend'
                                                    : formatDateToView(invoice.DataPagamento)}</td>
                                            <td><span>{invoice.InscricaoMunicipal}</span></td>
                                            <td>$ {formatMoney(invoice.ValorSaldo, 2, '.', ',')}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                                <div className="alert alert-warning" role="alert">
                                    No se encontraron pedidos
                                </div>
                            )

                    ) : (
                            <Loader short="false" />
                        )}

                </div>

                <div className="sect-footer align-items-start">
                    {/* <a href="/" className="btn btn--cinza btn--block btn-full btn--bold">Voltar</a> */}
                    {createElement('a', { href: '/', className: "btn btn--cinza btn--block btn-full btn--bold" }, "Regresar")}
                </div>
            </section>
            
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(PendenciesDetail);