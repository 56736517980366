import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

export const fetchVersion = (tokenLogin) => {
    const config = {
        headers: { Authorization: `Bearer ${tokenLogin ?? HTTP_TOKEN}` }
    }
    const options = {
        "Esquema": "web",
        "Tabela": "VersaoSistema"
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Versao/VersaoSistema`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchHomolog = (tokenLogin) => {
    const config = {
        headers: { Authorization: `Bearer ${tokenLogin ?? HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "VersaoHomologacao"
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Versao/VersaoHomologacao`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

