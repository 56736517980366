import { useEffect, useState, createElement } from 'react';
import logo from './logo-colorida.png';
import { getItemSession } from '../../../utils';

const Logo = () => {
  const [redirectToPage, setCaminho] = useState('');

  useEffect(() => {
    let filial = getItemSession('_filialSelecionada') || 0;

    if (filial === 2 || filial === "2") {
      setCaminho('https://bike.shimano.com/es-AR');
    } else if (filial === 6 || filial === "6") {
      setCaminho('https://fish.shimano.com/es-AR');
    }
  }, []);

  return (
    createElement('a', {
      href: redirectToPage,
      target: "_blank",
      rel: "noopener noreferrer",
      tabIndex: 0,
      className: "logo",
      "aria-label": ""
    },
      createElement('img', { src: logo, alt: "Logo da Blink" })
    )
  );
};

export default Logo;
