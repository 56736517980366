import React, { useEffect }from 'react';


import { scrollToTop } from '../utils';

const PerguntasFrequentesFAQs = () => {
    
    useEffect(() => {
        scrollToTop();
    });
    
 return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>Preguntas Frecuentes de Distribuidores SMX</h1>
                    </div>
                    <hr />
                </div>
                <div className="subtitulo-politics">
                <h4>1. ¿Como ser un cliente de Shimano Mexico?</h4>
                    <ul>
                        <li>
                        Tener una tienda física y estar dado de alta en Hacienda como persona física con actividad empresarial o persona moral.
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>2. ¿Documentos necesários para dar de alta?</h4>
                   <ul>
                         <li>
                            Constancia Fiscal Actualizada
                         </li>
                         <li>
                            Acta Constitutiva (En caso de ser persona Moral)
                        </li> 
                        <li>
                            Identificación Oficial (INE, Pasaporte o Cedula Profesional)
                       </li>  
                       <li>
                            Comprobante de Domicilio (mismo de la dirección fiscal)
                       </li>
                       <li>
                            Policas Comerciales Firmadas
                       </li>
                   </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>3. ¿Proceso para ingresar en el proyecto de SSC?</h4>
                    <ul>
                        <li>
                            Ser cliente y distribuidor de Shimano México
                        </li>
                        <li>
                            Mínimo 2 mecánicos certificados en S-TEC
                        </li> 
                        <li>
                            Compras regulares por un periodo mínimo de 3 meses
                        </li>  
                        <li>
                            Manejar todas nuestras marcas
                        </li>
                        <li>
                            Asignación de espacio para producto Shimano y sus marcas.
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>4. ¿Como tener acceso al sistema de compra en línea (B2B)?</h4>
                    <ul>
                        <li>
                        Preguntar a tu asesor comercial y asesor de Telemarketing que te brinden usuario y contraseña de acceso.
                        </li>                        
                    </ul>                   
                </div>

                <div className="subtitulo-politics">
                    <h4>5. ¿Contacto para problemas con pedidos, garantías, quejas, sugerencias o felicitaciones?</h4>
                   <ol>
                       <li>
                            Área de Post Ventas <b> 442 582 0239</b>
                       </li>                       
                   </ol>
                </div>
                
                <div className="subtitulo-politics">
                    <h4>6. ¿Estatus de entrega del pedido?</h4>
                    <ul>
                        <li>
                            Una vez que recibas el correo de “Expedido”, por favor solicita tu Guía de rastreo con tu asesor correspondiente.
                        </li>                        
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>7. ¿Participación de Shimano en eventos?</h4>
                    <ul>
                        <li>
                            Ser cliente activo de Shimano México
                        </li>
                        <li>
                            Compras constantes
                        </li> 
                        <li>
                            Tener bien definido el lugar del evento, fechas y horarios para validar disponibilidad
                        </li>  
                        <li>
                            Recibir el apoyo o si lo requiere el patrocinio necesario
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>8. ¿Datos bancarios para realizar pagos? </h4>
                    <ul>
                        <li>
                            Número de Cuenta – <b>0115375622</b>
                        </li>
                        <li>
                            CLABE Interbancaria – <b>012180001153756228</b>
                        </li> 
                        <li>
                            Beneficiario – <b>Shimano Bike & Fishing Mexico</b>
                        </li>                           
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>9. ¿Dudas con estados de cuenta?</h4>
                    <ul>
                        <li>
                            Cuando recibas un estado de cuenta y tengas dudas, contacta a tu asesor de ventas.
                        </li>
                        <li>
                            No respondes el correo a <b>@ventas</b> ya que son automáticos y nadie podrá visualizar.
                        </li>                        
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>10.	¿Dudas con datos de la factura? </h4>
                    <ul>
                        <li>
                            Revisar que los datos fiscales coincidan con tu factura
                        </li>
                        <li>
                            Revisar que los datos fiscales coincidan con tu factura
                        </li>                        
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>11.	¿Actualización para datos fiscales?</h4>
                    <ul>
                        <li>
                            Envía tu constancia fiscal a tu asesor de Telemarketing para la actualización.
                        </li>                       
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>12.	¿Problemas con acceso al B2B?</h4>
                    <ul>
                        <li>
                            Revisa que el usuario y contraseña sean correctos
                        </li>
                        <li>
                            Abre un nuevo link
                        </li> 
                        <li>
                            Intenta con otro navegador
                        </li>  
                        <li>
                            Elimina cookies y actualiza la pagina
                        </li>
                        <li>
                            Contacta a tu asesor de Telemarketing, ten a la mano tu usuario y contraseña
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>13. ¿Cómo y en cuánto tiempo me pueden otorgar una línea de crédito?</h4>
                    <ul>
                        <li>
                        Compras constantes y en un mínimo de 3 meses
                        </li>
                        <li>
                        Se otorga línea de crédito referente a tu promedio mensual
                        </li> 
                        <li>
                        Se generan nuevas políticas comerciales con los acuerdos
                        </li>  
                        <li>
                        Se deberá firmar tanto la política como un pagare para el inicio de la línea
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>14.	¿Cuentan con letreros, pegatinas y viniles, para nosotros como distribuidores?</h4>
                    <ul>
                        <li>
                            Si contamos con material POP, acércate con tu asesor comercial o de telemarketing para preguntar cómo y de qué manera obtener.
                        </li>
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>15. ¿Se tienen catálogos de la marca?</h4>
                    <ul>
                        <li>
                            Se tienen catálogos de las marcas que manejamos, pero solo digitales, físicos ya no realizaremos por cuidado del medio ambiente.
                        </li>                        
                    </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>16. ¿Manejan exhibidores, de sus marcas?</h4>
                    <ul>
                        <li>
                            Si manejamos exhibidores, se obtienen a través una extensa relación comercial con buenos históricos y sujetos a disponibilidad.
                        </li>                       
                    </ul>
                </div>
            </section>
            <br/>
            
        </>
    );
}

export default PerguntasFrequentesFAQs;