import React, { useEffect, useRef } from 'react';
import { useHistory  } from 'react-router-dom';
import { setItemSession, getItemSession } from '../utils';
import Cookies from 'js-cookie';

const Logout = () => {
    const history = useHistory();
    Cookies.remove('Notifications')
    useEffect(() => {
        if(window.sessionStorage.length > 0){
            var filial = getItemSession('_filialSelecionada');
            window.sessionStorage.clear();
            window.localStorage.clear();
            setItemSession('_filialSelecionada', filial);
            window.location.href = process.env.REACT_APP_URL_REDE_blink;
        }else{
            history.push("/");
        }
    }, [])

    return (
            <section className="conteudo_interno container">
                <div className="d-flex align-items-center">
                    <strong className="mr-2">Redireccionando... </strong>
                    <div className="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                </div>
            </section>
    )

}

export default Logout;