import React, { useEffect } from 'react';
// default


import { scrollToTop } from '../utils';

const Privacy = () => {
    useEffect(() => {
        scrollToTop();
    })

    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>Política de Privacidad</h1>
                    </div>
                    <hr />
                </div>
                <div className="subtitulo-politics">
                <h4>Política de privacidad para Shimano Bike and Fishing SA de CV.</h4>

                Toda su información personal recopilada se utilizará para ayudarle a hacer que su visita a nuestro sitio web sea lo más productiva y agradable posible.<br/>
                Garantizar la confidencialidad de los datos personales de los usuarios de nuestro sitio web es importante para Shimano bike and fishing México SA de CV.<br/>
                Toda la información personal relativa a miembros, suscriptores, clientes o visitantes que utilicen el sitio web de Shimano bike and fishing Mexico SA de CV será 
                procesada de conformidad con la Ley de Protección de Datos Personales en posesión de particulares del 5 de julio del 2010. Cuando realiza una transacción con nuestra 
                tienda como parte del proceso de compra y venta, recopilamos la información comercial que nos proporciona, como: nombre, correo electrónico y dirección.<br/>
                Cuando accede a nuestro sitio web, también recibimos automáticamente el protocolo de Internet de su ordenador, dirección IP, con el fin de obtener información 
                que nos ayuda a aprender sobre su navegador y sistema operativo. Email Marketing se realizará solo si lo permite. En estos correos electrónicos puede recibir noticias sobre nuestra tienda, nuevos productos y otras actualizaciones
                El uso del sitio web de Shimano bike and fishing Mexico SA de CV presupone la aceptación de este Acuerdo de Privacidad. El equipo de Shimano se reserva el derecho de cambiar este acuerdo sin previo aviso. Por lo tanto, le recomendamos que consulte nuestra política de privacidad regularmente para estar siempre al día.<br/>
                </div>

                <div className="subtitulo-politics">
                    <h4>Anuncios</h4>
                    Al igual que otros sitios web, recopilamos y utilizamos la información contenida en los anuncios. La información contenida en los anuncios incluye su dirección IP (Protocolo de Internet), 
                    su ISP (Proveedor de servicios de Internet u otro), el navegador que utilizó al visitar nuestro sitio web (como Google Chrome o Firefox), la hora de su visita y qué páginas visitó dentro de nuestro sitio web.
                </div>

                <div className="subtitulo-politics">
                    <h4>Seguridad</h4>

                    Para proteger su información personal, tomamos precauciones razonables y seguimos las mejores prácticas de la industria para asegurarnos de que no se pierda, utilice indebidamente, acceda, divulgue, altere o destruya de manera incorrecta.
                    Si nos proporciona su información de acceso, esta información se cifra mediante la tecnología de capa de socket seguro (SSL) y se almacena con cifrado AES-256. Aunque ningún método de transmisión por Internet o almacenamiento electrónico es 100% seguro, 
                    seguimos todos los requisitos PCI-DSS e implementamos estándares adicionales generalmente aceptados por la industria.
                </div>

                <div className="subtitulo-politics">
                    <h4>Uso de Cookies y Web Beacons</h4>

                    Utilizamos cookies para almacenar información, como sus preferencias personales cuando visita nuestro sitio web. Esto puede incluir una simple ventana emergente, o un enlace a varios servicios que proporcionamos, como formularios.
                    Usted tiene el poder de desactivar las cookies, en las opciones de su navegador o mediante cambios en las herramientas del programa Antivirus. Sin embargo, esto puede cambiar la forma en que interactúa con nuestro sitio web u otros sitios web. 
                    Esto puede afectar o no permitirle iniciar sesión en programas, sitios web o páginas de nuestras redes y otras.
                </div>

                <div className="subtitulo-politics">
                    <h4>Enlaces a Sitios de terceros</h4>

                    El sitio web de Shimano Bike and Fishing SA de CV. tiene enlaces a otros sitios, que, a nuestro juicio, pueden contener información útil /herramientas para nuestros visitantes. Nuestra política de privacidad no se aplica a sitios web de terceros, por lo que si visita otro sitio desde el nuestro, debe leer su política de privacidad.
                    No somos responsables de la política de privacidad o el contenido presente en estos mismos sitios. En general, los proveedores externos utilizados por nosotros solo recopilarán, utilizarán y divulgarán su información en la medida necesaria para permitirles realizar los servicios que nos proporcionan.
                    Sin embargo, ciertos proveedores de servicios externos, como pasarelas de pago y otros procesadores de transacciones de pago, tienen sus propias políticas de privacidad con respecto a la información que estamos obligados a proporcionarles de sus transacciones relacionadas con la compra. Para estos proveedores, le recomendamos que lea sus políticas de privacidad para que pueda comprender la forma en que esos proveedores utilizarán su información personal. En particular, recuerde que ciertos proveedores pueden estar ubicados o tener instalaciones que se encuentran en jurisdicciones diferentes a las de usted o nosotros. Por lo tanto, si desea continuar con una transacción que involucre los servicios de un proveedor de servicios externo, entonces su información puede estar sujeta a las leyes de la(s) jurisdicción(es) en la que se encuentra el proveedor de servicios o sus instalaciones.
                    Por ejemplo, si se encuentra en Canadá y su transacción es procesada por una pasarela de pago ubicada en los Estados Unidos, entonces su información personal utilizada para completar esa transacción puede estar sujeta a divulgación bajo la ley de los Estados Unidos, incluida la Ley Patriota.
                    Una vez que abandone el sitio web de nuestra tienda o sea redirigido a una aplicación o sitio web de terceros, ya no se regirá por esta Política de privacidad ni por los Términos de servicio de nuestro sitio.
                </div>

                <div className="subtitulo-politics">
                    <h4>Cambios en esta política de privacidad</h4>

                    Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento, por lo que le complace revisarla con frecuencia. Los cambios y aclaraciones entrarán en vigor inmediatamente después de su publicación en el sitio.
                    Si realizamos cambios sustanciales en esta política, le notificaremos aquí que se han actualizado, para que sepa qué información recopilamos, cómo la usamos y en qué circunstancias, si las hubiera, la usamos y/o divulgamos.
                </div>
                
            </section>
            
        </>
    );
}

export default Privacy;