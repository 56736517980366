import React, { useState, useEffect, createElement } from 'react';
import { fetchClient, fetchTelesales } from '../services/client-api';
import { disconnect, getItemSession, scrollToTop } from '../utils';

const Account = () => {
    const [client, setClient] = useState([]);
    const [seller, setSeller] = useState([]);

    const dados = JSON.parse(getItemSession('_dados'))
    const idCliente = dados.idCliente;

    useEffect(() => {
        scrollToTop();
    })

    // Load Data
    useEffect(() => {
        fetchClient(idCliente)
            .then(result => {
                if (result.data.Data) {
                    setClient(result.data.Data.Dados[0])
                }
            })
            .catch(reject => {
                disconnect();
                console.log(reject, 'fetchClient')
            })
    }, [idCliente])

    useEffect(() => {
      
          fetchTelesales()
            .then(result => {
                if (result.data.Data) {
                    const sortedSeller = result.data.Data.Dados.sort((a, b) => a.Ordem - b.Ordem);
                    setSeller(sortedSeller);
                }
            })
            .catch(reject => {
                console.log(reject, 'fetchTelesales')
            })          
    }, [])

   

    return (
        <>
            
            <section className="minha-conta container">
                <div className="minha-conta--wrapper">
                    <div className="sect-header">
                        <div className="sect-titulo">
                            <h2 className="titulo h2">Mi Cuenta</h2>
                        </div>
                        <hr />
                    </div>
                    <div className="minha-conta--conteudo">
                        <h4 className="titulo h4">{client.RazaoSocial}</h4>
                        <p className="sect-texto">{client.EnderecoCompleto}</p>
                        <br />
                        <hr className='mi-conta-hr' />
                        <div className='container-consultores'>
                        {seller.map(sellerItem => (
                            <div key={sellerItem.idUsuario}> 
                                <h4 className="titulo h4">{sellerItem.Titulo}</h4>
                                <div className="">
                                    <ul className="list-unstyled mb-0">
                                        <li>
                                            <div className="dropdown_cart_item_footer">
                                                <h5 className="titulo h5">{sellerItem.Nome}</h5>
                                                <p className="sect-texto"><b>Contactos:</b></p>
                                                <p className="sect-texto">{sellerItem.Email}</p>
                                                
                                                {sellerItem.Telefone &&
                                                    <p className="sect-texto">Teléfono: {sellerItem.Telefone}</p>
                                                }
                                            </div>
                                            <br />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="sect-footer align-items-start">
                    {createElement('a', { href: '/', className: 'btn btn--cinza btn--block btn-full btn--bold' }, "Regresar")}
                </div>
            </section>
            
        </>
    )
}

export default Account;