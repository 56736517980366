import React, { createElement } from 'react';
import {  getItemSession } from '../../../utils'

const MenuPrincipal = () => { 
    const login = JSON.parse(getItemSession('_dados'));

    return (
        <div className="header_nav_cont center" id="menuPrincipal">
            <h4 className="titulo h4">Menu principal</h4>
            <nav className="nav">
                <ul className="list-unstyled mb-0">
                    <li className="nav-item">
                        {!login?.ApenasVisualizacao ? <a className="nav-link" href="/minha-conta">Mi Cuenta</a> : ' '} 
                    </li>
                    <li className="nav-item">
                        {!login?.ApenasVisualizacao && <a className="nav-link" href="/pedidos">Mis Pedidos</a>}
                    </li>
                    <li className="nav-item">
                        {!login?.ApenasVisualizacao && <a className="nav-link" href="/pendencias">Pendientes</a>}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/busca?t=", className: "nav-link" }, "Categorias")} 
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine1", className: "nav-link" }, "Especiales")}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine2", className: "nav-link" }, "Noticias")} 
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine3", className: "nav-link" }, "Lanzamientos")}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine4", className: "nav-link" }, "Productos Increibles")}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/destaque/Vitrine5", className: "nav-link" }, "Promociones")}
                    </li>
                    <li className="nav-item">
                        {createElement('a', { href: "/service-center", className: "nav-link" }, "Shimano Service Center")}
                    </li>
                    <li className="nav-item">
                        {!login?.ApenasVisualizacao && <a role="button" href="#carrinho" className="nav-link nav-dropdown">Carrinho<span className="fa fa-chevron-right"></span></a> }
                    </li>
                    <br/>
                    <li className="nav-item">
                        {login?.ApenasVisualizacao ? <a className="nav-link" href='/logout'>Login</a> : <a className="nav-link" href="/login_vitrine">Cerrar sesión</a>}
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default MenuPrincipal;