import React, { useState, useRef, useEffect, createElement } from 'react';
import { useHistory } from "react-router-dom";


import { formatMoney, disconnect, setItemSession, getItemSession } from '../utils';
import {
    fetchProductsById,
    fetchProductsBySuggestion,
    fetchBanners
} from '../services/products-api';
import { Loader } from '../components/Loader/Loader';
import ProductCard from '../components/Product/ProductCard';
import { arrOrigin } from '../constants';
import { addToCart, fetchCartItems, updateCart } from '../services/cart-api';
import * as CartActions from '../store/actions/cart'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Slider from "react-slick";
import { connect } from 'react-redux';
import Magnifier from "react-magnifier";
import Cookies from 'js-cookie';
import ReactGA from 'react-ga'
import { fetchBanners as fetchDescritivo } from '../services/banners-api';

const Swal = require('sweetalert2');

const Product = ({ match, origin, refreshCartFn }) => {

    Cookies.remove('LinkProduto');
    useEffect(() => { ReactGA.pageview(window.location.pathname + window.location.search) }, [])
    const [isLoading, setLoading] = useState(true);
    const [isLoadingSuggestion, setLoadingSuggestion] = useState(true);
    const [product, setProduct] = useState('');
    const [productsSuggestion, setProdutosSuggestion] = useState([]);
    const [adding, setAdding] = useState(false);
    const [banners, setBanners] = useState([]);
    const [descritivo, setDescritivo] = useState([]);

    // Props
    const idProduto = match.params.id;
    const history = useHistory();
    const login = JSON.parse(getItemSession('_dados'));

    const [quantity, setQuantity] = useState(1)

    const decrement = () => setQuantity(q => isNaN(q) || q < 2 ? 1 : q - 1);
    const increment = () => setQuantity(q => q >= 0 ? q + 1 : 1);

    const handleChange = (e) => {
        if (parseInt(e.target.value) > 0) {
            setQuantity(parseInt(e.target.value));
        } else {
            setQuantity();
        }
    }

    // Load Data


    useEffect(() => {
        fetchProductsById(idProduto)
            .then(result => {
                setProduct(result.data.Data.Dados[0])
            })
            .then(result => setLoading(false))
            .catch(reject => {
                disconnect();
            })
    }, [])

    useEffect(() => {
        fetchProductsBySuggestion(4)
            .then(result => {
                setProdutosSuggestion(result.data.Data.Dados)
            })
            .then(result => setLoadingSuggestion(false))
            .catch(reject => {
                disconnect();
            })
    }, [])

    //Carregamendo do banner do produto
    useEffect(() => {
        fetchBanners(idProduto)
            .then(result => {
                setBanners(result.data.Data.Dados)
            })
            .catch(reject => {
                disconnect();
            })
    }, [])

    const HaveLink = ({ banner }) => {
        if (banner.Sequencia > 0) {
            return (
                <Magnifier
                    src={banner.Link}
                    width={300}
                    mgWidth={180}
                    mgHeight={180}
                    zoomFactor={1}
                />
            )
        }

        return <img src={banner.Link} alt="" style={{ margin: 'auto' }} />
    }

    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <button
                className={`btn btn--icon btn-left ${className}`}
                onClick={onClick}
                style={{ ...style }}
            >
                <FaChevronLeft size={14} color="#000" />
            </button>
        )
    }
    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <button
                className={`btn btn--icon btn-right ${className}`}
                onClick={onClick}
                style={{ ...style }}
            >

                <FaChevronRight size={14} color="#000" />
            </button>
        )
    }


    function customPaging(i) {
        return <span><img src={banners[i].Link} alt="" style={{ margin: 'auto', width: '100px', height: '100' }} /></span>;
    }


    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        pauseOnHover: true,
        customPaging: customPaging,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        loop: true,
        arrows: false,
        prevArrow: false,
        nextArrow: false
    };

    const handleAddToCart = (e) => {
        e.preventDefault();
        document.getElementById("botaoRoxoPrincipal").setAttribute("disabled", "disabled");;
        const intQuantity = parseInt(quantity);

        if (isNaN(intQuantity) || intQuantity < 1) {
            alert("Inserta una cantidad valida para el producto.");
            return false;
        }

        setAdding(true);

        buttonActionDisabled(e);

        // filtro para ver se produto já está no carrinho
        const get = getItemSession('_carrinho');
        const cartItems = JSON.parse(get);

        const item = cartItems.Dados.filter(item => { return item.idProduto === product.idProduto })

        let time = 2000;

        if (item.length > 0) {
            const newQuantity = intQuantity + parseInt(item[0].Quantidade)

            updateCart(product.idProduto, item[0].Item, newQuantity)
                .then(async resultUpdate => {
                    if (resultUpdate.data.Codigo === 500) {
                        alert(resultUpdate.data.Status);
                        setAdding(false);
                        time = 0;
                    } else {
                        await fetchCartItems()
                            .then(result => {
                                if (result.data.Data.Dados.length > 0) {
                                    setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                    refreshCartFn(result.data.Data)
                                }
                                setAdding(false);
                            })
                            .catch(reject => {
                                disconnect();
                            })

                    }
                })
                .catch(reject => {
                    disconnect();
                })

        } else {
            addToCart(product.idProduto, intQuantity, arrOrigin['pagina_produto'])
                .then(resultAdd => {
                    if (resultAdd.data.Codigo === 500) {
                        if (resultAdd.data.CodigoInterno === 4) {
                            disconnect()
                        } else {
                            alert(resultAdd.data.Status);
                            setAdding(false);
                            time = 0;
                        }
                    } else {
                        fetchCartItems()
                            .then(result => {
                                if (result.data.Data.Dados.length > 0) {
                                    setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                    refreshCartFn(result.data.Data)
                                }
                                setAdding(false);
                            })
                            .catch(reject => {
                                disconnect();
                            })
                    }
                })
                .catch(reject => {
                    disconnect();
                })
        }


        buttonActionEnabled(e);
    }

    const buttonActionDisabled = (e) => {
        e.target.disabled = true;
    }
    const buttonActionEnabled = (e) => {
        e.target.disabled = false;
    }

    return (
        <>
            
            <section className="conteudo_interno conteudo_padrao container produtos-detalhe">
                <div className="produtos-detalhe--wrapper">
                    {!isLoading ? (
                        <>
                            <div className="sect-header">
                                <h4 className="titulo h2">Producto - {product.Descricao}</h4>
                                <hr />
                            </div>

                            <div className="produtos-detalhe--conteudo">
                                <div className="carrossel_produtos">
                                    {banners &&
                                        <Slider {...settings}>
                                            {banners.map((banner, index) => (
                                                <div className="carrossel_produtos" key={index}>
                                                    <HaveLink banner={banner} />
                                                </div>
                                            ))}
                                        </Slider>
                                    }
                                </div>
                                <div className="produtos-detalhe--body">
                                    <h4 className="titulo h4">{product.Descricao}</h4>
                                    <p className="produtos-detalhe--codigo">Código: {product.CodigoProduto.substring(3)}</p>
                                    <p className="produtos-detalhe--codigo">PN: {product.EAN}</p>

                                    {!login.ApenasVisualizacao &&
                                        <div className="produtos-detalhe--info">
                                            <div className="misc">
                                                <p>Embalaje</p>
                                                <p>{product.Embalagem}</p>
                                            </div>
                                            <div className="misc">
                                                <p>Cantidad caja</p>
                                                <p>{product.UnidadesPorCaixa}</p>
                                            </div>
                                            <div className="misc">
                                            {product.PossuiPrecoPromocional
                                        ?
                                        <>
                                            <p className="produtos-detalhe--valor-total"><b>Total Caja: de <strike>$ {formatMoney(product.Preco, 2, '.', ',')}</strike> por $ {formatMoney(product.PrecoPromocional, 2, '.', ',')}</b></p>
                                        </>
                                        :
                                        <>
                                            {!login.ApenasVisualizacao ?
                                                <>
                                                    <p className="produtos-detalhe--valor-total"><b>Total Caja $ {formatMoney((product.PrecoUnitario * product.UnidadesPorCaixa), 2, '.', ',')}</b></p>
                                                </>
                                                :
                                                <>
                                                    <p className="produtos-detalhe--valor-total"><b>Total Caja $ {formatMoney((product.PrecoUnitario * product.UnidadesPorCaixa), 2, '.', ',')}</b></p>
                                                </>
                                            }


                                        </>
                                    }
                                            </div>
                                        </div>
                                    }
                                    {login.ApenasVisualizacao &&
                                        <h6 className="card-item--codigo">
                                            Precio Publico Sugerido
                                        </h6>
                                    }
                                    {!login.ApenasVisualizacao &&
                                        <h6 className="card-item--codigo">
                                            Precio Lista
                                        </h6>
                                    }
                                    {product.PossuiPrecoPromocional
                                        ?
                                        <>
                                            <p className="produtos-detalhe--valor--de"><strike>$ {formatMoney(product.PrecoUnitario, 2, '.', ',')}</strike> <small>unidad</small></p>
                                            <p className="produtos-detalhe--valor--por">$ {formatMoney(product.PrecoPromocionalUnitario, 2, '.', ',')} <small>unidad</small></p>
                                        </>
                                        :
                                        <>
                                            {!login.ApenasVisualizacao ?
                                                <>
                                                    <p className="produtos-detalhe--valor">$ {formatMoney(product.PrecoUnitario, 2, '.', ',')} 
                                                        <small>unidad</small></p><p className="produtos-detalhe--valor-total"></p>
                                                </>
                                                :
                                                <>
                                                    <p className="produtos-detalhe--valor">$ {formatMoney(product.ValorTabela, 2, '.', ',')} <small>unidad</small></p><p className="produtos-detalhe--valor-total"></p>
                                                </>
                                            }


                                        </>
                                    }

                                    {!login.ApenasVisualizacao ? <div className="produtos-detalhe--estoque">{product.Estoque > 0 ? `${product.Estoque} disponibles` : <span className="badge badge-black-white">Producto no disponible</span>}</div> : ''}
                                    {/* {!login.ApenasVisualizacao ? <div className="produtos-detalhe--estoque">{product.Estoque > 0 ? `disponibles` : <span className="badge badge-black-white">Producto no disponible</span>}</div>: ''}                                     */}


                                    <hr />
                                    {product.Estoque > 0 && !login.ApenasVisualizacao &&
                                        <form className="form-adicionar">
                                            <button type="button" name="btn-item" className="btn form-adicionar--btn" onClick={decrement}>-</button>
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={quantity}
                                                className="card-item--counter"
                                                onChange={handleChange}
                                                maxLength={4}
                                            />
                                            <button type="button" name="btn-item" className="btn form-adicionar--btn" onClick={increment}>+</button>
                                            <button
                                                type="button"
                                                onClick={handleAddToCart}
                                                class={`btn btn--sm btn--roxo btn--bold ${adding && 'active'}`}
                                                id="botaoRoxoPrincipal"
                                            >
                                                {adding
                                                    ? <><div className="spinner-border spinner-border-sm text-light"></div> Añadiendo</>
                                                    : 'Agregar'
                                                }
                                            </button>
                                        </form>
                                    }
                                </div>
                            </div>
                        </>
                    ) : (
                        <Loader short="false" />
                    )}

                    {product.LinkImagemDescritivo ?
                        <>
                            <hr />
                            <h4 className="titulo h2">Descritivo</h4>
                            <div className='produto-descritivo banner-container flex row center-center m-auto full-view'>

                                {product.LinkImagemDescritivo ?
                                    <img src={product.LinkImagemDescritivo} alt="Imagen no cargada" />
                                    :
                                    <img src="https://cdn.blink.com.br/Arquivos/sem-foto.png" alt="Imagen no cargada" />
                                }
                            </div>
                            <hr />
                        </>
                        :
                        ''
                    }

                    <h4 className="titulo h4 mais-produtos-titulo">Productos increíbles</h4>
                    <div className="cards-container">
                        <div className="overflow-auto">
                            <div className="cards cards-produto mais-produtos-4-cards cards-produto-width-4">
                                {!isLoadingSuggestion ? (
                                    productsSuggestion.map(product =>
                                        <ProductCard
                                            key={`product-detail-card-${product.idProduto}`}
                                            product={product}
                                            origin={arrOrigin['pagina_produto_incrementar']}
                                        />
                                    )
                                ) : (
                                    <Loader short="false" />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="sect-footer align-items-start">
                        <button className="btn btn--cinza btn--block btn-full btn--bold"
                            onClick={() => { history.goBack(); }}>Regresar</button>
                    </div>
                </div>
            </section>
            
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(Product); 