import React, { createElement } from 'react';
import ProductCard from '../Product/ProductCard';
import { arrOrigin } from '../../constants';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
    loop:true, 
    items:4, 
    nav:true, 
    autoplay:true, 
    autoplayHoverPause:true,
    navText: ['',''],
    navClass:['nav-btn prev-slide','nav-btn next-slide'],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        }
    },
};

const Especiales = ({ destaque }) => {
    return (
        destaque.length > 0 &&
        <>
            <div className="justify-content-between align-items-center div_titulo">
                <h4 className="titulo h4 titulo_new">Especiales</h4>
                {/* <a href="/destaque/promocao" className="link-texto text-decoration-none mr-md-3">ver todas</a> */}
                {createElement('a', { href: "/destaque/Vitrine1", className: "link-texto btn_view text-decoration-none mr-md-3" }, "ver todo")}
            </div>
            <div className="overflow-auto">
                    <div className="cards cards-produto cards-produto-width-4 d-flex justify-content-between align-items-center">
                        { destaque.length > 0 && <OwlCarousel 
                            className="owl-theme" 
                            {...options}
                        >
                        {destaque.map(product =>
                            <ProductCard
                                key={`promotion-card-${product.idProduto}`}
                                product={product}
                                origin={arrOrigin['home_promocao']}
                            />
                        )}
                        </OwlCarousel>
                        }
                    </div>
            </div>
        </>
    );
}

export default Especiales;