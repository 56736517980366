import React from 'react';
import '../../scss/common.scss';
import '../../scss/tipbar.scss';
import { FaBoxOpen, FaShieldAlt, FaTruck, FaBarcode, /*FaSortDown*/ } from 'react-icons/fa'
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
import { getItemSession } from '../../utils';
import { Link } from 'react-router-dom';


const TipBarFooter = () => {


  const login = JSON.parse(getItemSession('_dados'));
  

return (
<>
 
  <div className="footer__tipBar">
    <div className="box">
      <div className="container">
        <div className="blkTipBox">
          <div className="row">
            <div className="col-md-3 col-12 d-flex flex-row flex-wrap justify-content-center">
              <div className="tipBar">
                <div className="tipBar__icon"><FaBoxOpen color="white" /></div>
                <div className="tipBar__texts">
                  <p><strong>Envio Gratis</strong></p>
                  <p>En todas las compras mayores a $5,000.00 + IVA</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-12 d-flex flex-row flex-wrap justify-content-center">
              <div className="tipBar">
                <div className="tipBar__icon"><FaShieldAlt color="white" /></div>
                <div className="tipBar__texts">
                  <p><strong>Productos de Calidad</strong></p>
                  <p>Ofrecemos productos de calidad garantizada y marcas de renombre mundial.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-12 d-flex flex-row flex-wrap justify-content-center">
              <div className="tipBar">
                <div className="tipBar__icon"><FaTruck color="white" /></div>
                <div className="tipBar__texts">
                  <p><strong>Entrega Garantizada</strong></p>
                  <p>La mejor disponibilidad de producto, entrega rápida y garantizada de principio a fin.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-12 d-flex flex-row flex-wrap justify-content-center">
              <div className="tipBar">
                <div className="tipBar__icon"><FaBarcode color="white" /></div>
                <div className="tipBar__texts">
                  <p><strong>Forma de Pago</strong></p>
                  <p>Más comodidad para realizar sus pagos.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* <div className="footer__links">
    <div className="container">
      <div className="row">
        <div className="footer__list">
          <ul className="slick-initialized slick-slider">
            <button className="slick-prev slick-arrow"></button>
            <div className="slick-list">
              <div className="slick-track"></div>
            </div>
          </ul>
        </div>
        <button className="footer__button">
          <span>Mais informações <FaSortDown color="white"/></span>
        </button>
      </div>
    </div>
  </div> */}
  <div className="footer__main">
    <div className="container">
      <div className="d-flex ml-2">
        <div className="col-xs-12 col-md-3 ml-4">
          <div className="list-unstyled">
            <strong>Contacto</strong>
            <ul className="list-unstyled">
              <li >Carretera Estatal 431 kilómetro 2.2 INT Lote 18 COL. El Colorado, El Marques, Querétaro C.P.76246<br/><br/></li>           
              <li>
                <span>Lunes a Viernes de 8:00hrs a 18:00hrs.</span>
              </li>
              <br />
              <li>
                  <strong>Canal de denuncias</strong>
              </li>
              <li>
                  <span>denunciaexterna@shimanomexico.com</span>
     
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="footer__links-institucionais">
            <div className="list-unstyled">
              
                <strong>A cerca de la empresa</strong>
                <div className="list-unstyled">
                  {/* <li><Link to="/empresa">La empresa</Link></li> */}
                  <li><Link to="/historia-empresa">Historia de shimano</Link></li>
                  <li><Link to="/politica-de-troca-devolucao">Política de Cambio y Devolución</Link></li>
                  <li><Link to="/politica-de-privacidade">Política de Privacidad</Link></li>
                  <li><Link to="/politica-comercial">Política Comercial</Link></li>
                  <li><Link to="/faq">Preguntas Frecuentes (FAQs)</Link></li>
                </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-1">
          <div className="footer__links-conta">
          {!login?.ApenasVisualizacao &&
            <div className="list-unstyled">
                <strong>Mi Cuenta</strong>
                <div className="list-unstyled">
                <li className="nav-item">
                       <a href="/pedidos">Mis Pedidos</a>               
                    </li>
                    <li className="nav-item">
                       <a href="/pendencias">Pendientes</a>
                        
                    </li>
                </div>
            </div>
          }
          </div>
        </div>
        
        <div className="col-xs-10 col-md-3">
        {login?.ApenasVisualizacao &&
              <div className="footer__links-conta" style={{  backgroundColor: "#00aeef", 
                          color: "white", 
                          padding: "10px 20px", 
                          borderRadius: "5px", 
                          textDecoration: "none",
                          textAlign: "center",
                          marginRight:"50px",
                          marginLeft:"80px"
                        }}>
              <a href="https://app.pipefy.com/public/form/NTjUZkln" target="_blank" rel="noopener noreferrer" className="footer__button">              <span>REGISTRO DE NUEVOS CLIENTES</span>
                </a>
              </div>
               }
            </div>
       
        <div className="row col-xs-12 col-md-3">
          <div className="footer__security">
            <strong>Sellos y Redes Sociales</strong>
            <ul className="list-unstyled footer__selos">
              <li>
                <img src="https://bluecycle.vteximg.com.br/arquivos/selo_ssc_small.png?v=637389788671230000" alt="Selo Shimano Service Center" />
              </li>
            </ul>
            <div className="footer__social">
              <ul className="list-unstyled">
                <li>
                  <a className="facebook" href="https://www.facebook.com/ShimanomtbMexico/" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a className="instagram" href="https://www.instagram.com/shimanomx/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a className="linkedin" href="https://www.linkedin.com/company/shimanomexico/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                  </a>
                </li>
                <li>
                  <a className="youtube" href="https://www.youtube.com/channel/UCE2eNXmYi2-3Xa8YRQTPL8g" target="_blank" rel="noopener noreferrer">
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
);
}

export default TipBarFooter;