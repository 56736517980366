import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

export const fetchAllPendencies = (NumeroDocumento = null, DataInicial = null, DataFinal = null, ApenasPagos = null,  ApenasVencidos = null) => {
    
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "Pendencia",
        "Parametros": [
        ]
    }
    if (NumeroDocumento != null && NumeroDocumento !== '') {
        options.Parametros.push({ "Nome": "NumeroDocumento", "Valor": NumeroDocumento })
    }
    if (DataInicial && DataFinal && DataInicial != null && DataFinal !=null) {
        options.Parametros.push(
            {
                "Nome": "DataInicial",
                "Valor": DataInicial
            },
            {
                "Nome": "DataFinal",
                "Valor": DataFinal
            }
        )
    }

  /*  if(ApenasPagos && ApenasPagos !=null){
        options.Parametros.push(
            {
                "Nome": "ApenasPagos",
                "Valor": ApenasPagos
            }
        )
    }

    if(ApenasVencidos && ApenasVencidos !=null){
        options.Parametros.push(
            {
                "Nome": "ApenasVencidos",
                "Valor": ApenasVencidos
            }
        )
    }*/


    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchAllPendenciesById = (idPendencia) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {
        "Esquema": "web",
        "Tabela": "Pendecia",
        "Parametros": [
            {
                "Nome": "idPendencia",
                "Valor": idPendencia
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}


