import React from 'react';
import { getItemSession } from '../../../utils';

const DropdownCartResponsive = () => {
    const login = JSON.parse(getItemSession('_dados'));

    return (
        <>
            {!login?.ApenasVisualizacao &&
                <div className="dropdown_cart">
                    <button className="btn btn--icon dropdown-toggle" type="button">
                        <span className=" icon icon-shopping-cart"></span>
                    </button>
                </div>
            }
        </>
    )
}

export default DropdownCartResponsive;