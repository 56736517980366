import React, { useState, useEffect } from 'react';
// default


import { scrollToTop } from '../utils';
import styles from '../scss/history.css';
import Modal from "react-bootstrap/Modal";
import { FaWindowClose } from 'react-icons/fa';
import imgHistory from './../img/Historia.png';


const CompanyHistory = () => {
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        scrollToTop();
    });

    const imgModal = (e) => {
        setIsOpen(false)


    };

    let yrs = ['1921', '1981', '1991', '2001', '2011', '2021'];

    const showHistory = (e) => {
        for (let index = 0; index < yrs.length; index++) {
            var ocul = document.getElementById(yrs[index]);
            if (yrs[index] === e.target.value) {
                ocul.style.display = "block";
            } else {
                if (ocul.style.display === "block") {
                    ocul.style.display = "none";
                }
            }
        }
    }


    return (
        <>
            <Modal show={isOpen} id="myModal2" className="modal modal-boleto fade">
                <Modal.Header>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={imgModal}>
                        <FaWindowClose />
                    </button>
                </Modal.Header>
                <Modal.Body id='img012'>
                    <img alt='Shimano Historia' src={imgHistory} />
                </Modal.Body>
            </Modal>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>Historia de Shimano</h1>
                    </div>
                    <hr />
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="1921" onClick={showHistory} > 1921 -1980 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="1981" onClick={showHistory} > 1981 -1990 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="1991" onClick={showHistory} > 1991 -2000 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="2001" onClick={showHistory} > 2001 -2010 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="2011" onClick={showHistory} > 2011 -2020 </button>
                            </div>
                            <div className="col-md-4 col-sm-4 text-center">
                                <button className="btn btn-info btn-block" value="2021" onClick={showHistory} > 2021 </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {/* 1921 */}
                <div className="row" id="1921" style={{ display: 'block' }}>
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1921</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shozaburo Shimano establece Shimano Iron Works.</h3>
                                <p className="text_history">
                                    En febrero de 1921 abrió las forjas de Shimano en Higashi Minato en la ciudad de Sakai cuando solo tenía 26 años
                                    de edad. El espacio de sólo 40 metros cuadrados había sido una fábrica de celuloide demolida. La renta mensual
                                    de este espacio era de solamente 5 yenes. En ese momento la fábrica, solo contaba con un torno el cual medía aproximadamente
                                    1.8 metros de largo, él lo habría pedido prestado a las forjas de Sano Iron Works, beneficiándose de su amistad con el dueño.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1921/1921.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1922</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Comienza la producción del piñón libre para bicicleta.</h3>
                                <p className="text_history">
                                    Para el momento que Shozaburo funda su negocio, él había decidido producir piñones libres. De todos los componentes
                                    para bicicleta, los piñones requieren de los niveles más altos de tecnología para ser producidos.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1921/1921_2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1940</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El nombre de la compañía cambia a Shimano Iron Works Company Limited</h3>
                                <p className="text_history">
                                    En 1940, Shimano Iron Works Limited fue fundado por Shozaburo Shimano como su primer presidente.
                                    El número de empleados era de alrededor de 300.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1940/1940.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shozaburo Shimano se convierte en el primer presidente.</h3>
                                <p className="text_history">
                                    En enero 15 de 1940, la primera reunión organizacional de la compañía se llevó a cabo, en ese
                                    momento Shozaburo Shimano se convirtió en el primer presidente.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1951</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La compañía cambia de nombre a Shimano Industrial Co., Ltd.</h3>
                                <p className="text_history">
                                    En febrero de 1951, Shimano Iron Works absorbe a Shimano Bicycle Company y el
                                    nombre de la compañía se cambia a Shimano Industrial Company.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1956</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La producción del primer cambio de velocidad externo comienza.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1957</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La producción de primer cambio de velocidad interno ( de 3 velocidades ) comienza.</h3>
                                <p className="text_history">
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1957/1957.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La investigación de forja en frío comienza.</h3>
                                <p className="text_history">
                                    Shimano industrial comienza a investigar sobre la forja en frío en 1957
                                    derivado de una visita a una exhibición de una compañía de Alemania del oeste.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1958</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Fallece Shozaburo Shimano </h3>
                                <p className="text_history">
                                    En septiembre de 1958 fallece Shozaburo Shimano.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shozo Shimano se convierte en el segundo presidente.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1958/1958.png" alt='Shozo Shimano' className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1961</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Por primera vez se muestra el centro de tres velocidades en el Show Internacional de los Estados Unidos.</h3>
                                <p className="text_history">
                                    Se muestra en el Show Internacional Juguetes y Bicicletas el centro de tres velocidades llevado a cabo en la ciudad de Nueva York.
                                    Las ventas de bicicletas se encuentran en crecimiento.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1965</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La corporación Shimano de América se establece en Nueva York.</h3>
                                <p className="text_history">
                                    La continua existencia de Shimano en el mercado americano parece estar asegurada,
                                    es así que se establece la Shimano American Corporation en la ciudad de Nueva York.
                                    Yoshizo Shimano se convierte en presidente.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1965/1965.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1970</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Yamaguchi Corporation Limited (actual fábrica de Shimonoseki) es establecida en la prefectura de Yamaguchi en Japón.</h3>
                                <p className="text_history">
                                    El sistema de producción ha sido reforzado con esta nueva fábrica que ahora produce frenos de riel en una cantidad de 50,000 unidades por mes.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1970/1970.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La división de equipo para pesca es inaugurada.</h3>
                                <p className="text_history">
                                    La división de equipo para pesca es inaugurada como segundo pilar del negocio que se alinea a la visión de la compañía:
                                    contribuir a la promoción de la salud pública impulsada por las actividades al aire libre.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1971</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La serie DUX de rieles giratorios se presenta al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1971/1971.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1972</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Europa GMBH es establecida de Dusseldorf al oeste de Alemania.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las acciones de Shimano son listadas en la segunda sección de la Bolsa de Valores de Osaka.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1973</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Racing Team es lanzado.</h3>
                                <p className="text_history">
                                    En adición al activo patrocinio del equipo de ciclismo de Europa, Shimano crea su propio equipo
                                    doméstico de ciclismo, el Shimano Racing Team en Japón.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Singapur) Pte. Ltd. es establecido.</h3>
                                <p className="text_history">
                                    Es establecida la primer fábrica exterior en el estado industrial de Jurong en Singapur.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1973/1973-1.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La lista de acciones sube al segundo lugar en la Bolsa de Valores de Tokio.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La lista de valores sube a la primera y segunda sección de las Bolsas de Valores de Tokio y Osaka.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La serie de componentes para carreras DURA-ACE es lanzada al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1973/1973-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1974</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Positrón es lanzado al mercado.</h3>
                                <p className="text_history">
                                    Esta equipado con el sistema positrón que tiene un mecanismo de cambio indexado.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1976</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series DURA-ACE 10 son lanzadas al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1978</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series Bantam de carretes son lazadas en los Estados Unidos.</h3>
                                <p className="text_history">
                                    Un carrete ultraligero de precisión enfocado a los pescadores americanos de lubina es introducido al mercado americano en el AFTMA Show.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La serie DURA-ACE EX 7200 es introducida al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1979</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La Shimano American Corporation es transferida a New Jersey.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1980</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La serie DURA-ACE ax 7300 es lanzada al mercado.</h3>
                                <p className="text_history">
                                    Shimano introduce la serie DURA-ACE ax 7300 enfocada en reducir el arrastre aerodinámico durante el IFMA show en Koln al oeste de Alemania.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                </div>

                {/* 1981 */}
                <div className="row" style={{ display: 'none' }} id="1981">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1981</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Kumamoto equipo para pesca es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las acciones de Dunphy Holding Pty Ltd son adquiridas.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1982</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las primeras series SHIMANO 105 son lanzadas al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las primeras series DEORE XT son lanzadas al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1983</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Canada Ltd. es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La serie BB-X son lanzadas al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>SHIMANO 600EX es lanzado al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1984</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La primer Shimano Greenpia Road (actualmente carrera Shimano Suzuka) es llevada a cabo.</h3>
                                <p className="text_history">
                                    Shimano inicia la Greenpia Road la cual es un evento para ofrecer disfrute y diversión por el deporte del ciclismo y así popularizar el ciclismo en Japón.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1984/1984-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La copa Shimano Japón es llevada a cabo.</h3>
                                <p className="text_history">
                                    Shimano inicia la Shimano Japan Cup es una competición de pesca que ofrece disfrute y diversión por la pesca.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1984/1984.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series DURA-ACE 7400 equipadas con el Shimano Index System (SIS) son lanzadas al mercado.</h3>
                                <p className="text_history">
                                    La función remarcable del DURA-ACE 7400 es el Shimano Index System (SIS) que permite un posicionamiento seguro del engrane de cambio de velocidades por una sola acción.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1985</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Europa es transferido a Hilden al oeste de Alemania.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series Titanos de carretes con una nueva aleación se lanzan al mercado.</h3>
                                <p className="text_history">
                                    Titanos utiliza una avanzada composición la cual combina fibra de titanio, fibra de carbono y materiales de resina.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1986</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano American Corporation se une con Shimano Sales Corporation y se traslada a Irvine California en New Jersey.</h3>
                                <p className="text_history">
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La nueva serie Deore XT se lanza al mercado.</h3>
                                <p className="text_history">
                                    Los nuevos componentes de Deore XT con SIS para bicicletas de montaña son presentados después de cuatro años del grupo original Deore XT.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La serie Sante está en el mercado.</h3>
                                <p className="text_history">
                                    El concepto básico del desarrollo de Sante es buscar la belleza en los componentes del diseño. En otras palabras,
                                    Sante está diseñado para atraer a una amplia variedad de usuarios potenciales, en vez de un puñado de ciclistas profesionales.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series de SHIMANO 105 con SLR están en el mercado.</h3>
                                <p className="text_history">
                                    Las nuevas series de Shimano 105 incorporan el sistema de frenado lineal de Shimano (SLR) que le da al ciclista un mayor control sobre los calipers de los frenos.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1987</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La serie SHIMANO 600 Ultegra es lanzada al mercado.</h3>
                                <p className="text_history">
                                    Esta serie incorpora todo el grupo DURA-ACE incluyendo el SIS de 7 velocidades.                                        </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1988</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Andy Hampsten (Equipo Seven-Eleven) gana el Giro d´Italia con DURA-ACE.</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano U.K. Ltd. es establecido, comienza la venta de equipo para pesca en Europa.</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El FROM el cual es un sistema de caña especializado para la pesca de carpa cruciana de cuerpo profundo sale al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las cañas telescopicas Fine Carbon a Tokei y Fine Carbon a Ayu son lanzadas al mercado.</h3>
                                <p className="text_history">
                                    La característica única de la caña telescópica son su longitud ajustable, la cual permite ser usada para diferentes tipos de pesca dependiendo de las condiciones que el entorno demande.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Los zapatos de carreras SH-R100 son lanzados al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1989</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El Centro de Distribución Shimano Rinkai es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1989/1989.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Benelux B.V. y Shimano Europe Fishing Holding B.V. son establecidos.</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>STI (Shimano Total Integration) palanca de cambio de disparo rápido remoto para bicicletas off road es lanzado.</h3>
                                <p className="text_history">
                                    El sistema de disparo rápido el cual es el primer STI permite al ciclista hacer cambios operar los frenos y girar sin siquiera mover las manos del manubrio.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Nexus es lanzado como una nueva marca de equipo para pesca.</h3>
                                <p className="text_history">
                                    Shimano comienza con el equipo de pesca Nexus, coordinado junto con el equipo de pesca y vestimenta Shimano para mayor disfrute de la pesca.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1990</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Components (Malasia) Sdn. Bhd es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1990/1990.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Italy Fishing Sdn.  Bhd. establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Pedaling Dynamics (SPD) es lanzado al mercado.</h3>
                                <p className="text_history">
                                    La línea de zapados y pedales llamados SPD son diseñados para ser ideales en la eficiencia de pedaleo y el caminado.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>DURA-ACE (7400 series) con control de palanca dual es lanzado al mercado.</h3>
                                <p className="text_history">
                                    El control de palanca dual incorpora un mecanismo de cambio a la palanca de frenos haciendo posible el cambio de velocidades sin siquiera mover las manos del manubrio.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>


                </div>

                {/* 1991 */}
                <div className="row" style={{ display: 'none' }} id="1991">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1991</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El nombre corporativo es cambiado de Shimano Industrial Co., Ltd. a Shimano Inc.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Batam PT es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1991/1991.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Los componentes para bicicleta de montaña XTR series son lanzados al mercado.</h3>
                                <p className="text_history">
                                    XTR es la primera línea de componentes para bicicleta de montaña enfocados a competidores profesionales.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La primera Shimano RE-EX CROSS COUNTRY es llevada a cabo.</h3>

                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>En el aniversario número 70 el lema “Closer to Nature, Closer to People” es elegido como el mensaje corporativo oficial de Shimano.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1992</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Keizo Shimano se convierte en el tercer presidente.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1992/1992.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El Cycle Center (Museo de la Bicicleta) abre sus puertas.</h3>
                                <p className="text_history">
                                    En coordinación con la Ciudad de Sakai, donde Shimano se encuentra localizado, es establecido un museo en el área contigua al Parque Daisen.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1992/1992-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Kunshan) Bicycle Components Co. Ltd. es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El primer modelo de STELLA es lanzado, el Shimano balance lock (SBL) es lanzando.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1992/1992-3.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Australia Pty. Ltd. es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1993</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>DURA-ACE 7410 es lanzado al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1995</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Yoshizo Shimano se convierte en el cuarto presidente.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1995/1995.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo 1995 de STELLA es lanzado al mercado.</h3>
                                <p className="text_history">
                                    Equipado con SHIP (Smooth and High Power System) mejora notablemente el sistema del carrete.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1996</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>DURA-ACE 7700 es lanzado al mercado.</h3>
                                <p className="text_history">
                                    La serie 7700 es desarrollada después de un intensivo análisis al sistema desviador de la serie previa, basando en la filosofía del “Stress-Free”.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1997</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>G.Loomis, Inc Se integra al equipo Shimano.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Auto D es introducido al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1998</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo de STELLA 1998 es introducido al mercado.</h3>
                                <p className="text_history">
                                    Es el tercer modelo fue desarrollado con un cuerpo extremadamente delgado basado descubrimiento conceptual:
                                    en vez de reducir las partes del riel, el equipo de desarrollo buscó la manera de envolver las partes de forma más compacta.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La tienda de golf Aifie es inaugurada.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo del 25 aniversario de DURA-ACE es lanzado al mercado.</h3>
                                <p className="text_history">
                                    Esta serie de DURA-ACE especial fue lanzado para conmemorar los 25 años de su introducción al mercado.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano HB system para snowboards es introducido al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El sistema Fligthdeck es introducido al mercado.</h3>
                                <p className="text_history">
                                    La instalación de un switch de operación en la palanca de frenos permite al usuario controlar esto sin quitar las manos del manubrio.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>1999</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La primera Carrera de Ruta Shimano Motegi es llevada a cabo.</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El set de ruedos para bicicletas de ruta WH-7700 es lanzado.</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2000</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El set de ruedos para bicicletas de montaña WH-M959 es lanzado al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El STELLA modelo Millenium es lanzado al mercado.</h3>
                                <p className="text_history">
                                    El STELLA edición Millenium el cual se adquiría bajo pedido contaba con un sistema de oscilación super lenta, diseñado para incrementar la distancia de lanzamiento. Este también fue el primer modelo en incorporar el manillar enroscable. El cuerpo entero era cromado y tenía la inscripción “Millenium Edition” grabada con láser.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>
                </div>

                {/* 2001 */}
                <div className="row" style={{ display: 'none' }} id="2001">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2001</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Yozo Shimano se convierte en el quinto presidente.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2001/2001.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo 2001 de STELLA es lanzado al mercado.</h3>
                                <p className="text_history">
                                    Shimano continua el desarrollo del Sistema de Oscilación Super Lenta implementado en la edición Millenium. El equipo de desarrollo también identifica el efecto de las vibraciones de enrollado en el agarre del pescador. Una amplia variedad de configuraciones permite al usuario seleccionar el carrete que más se adapta al diámetro de cada caña.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Shanghai) Bicycle Components Co., Ltd es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2001/2001-2.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano República Checa sro es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Francia Composants Cycles S.A.S es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2002</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Taiwan) Co. Ltd es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2003</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Kunshan) fishing Tackle Co., Ltd. es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2003/2003.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Tianjin) Bicycle Components Co., Ltd. es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2003/2003-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2004</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo 2004 de STELLA es introducido al mercado.</h3>
                                <p className="text_history">
                                    El manillar de una sola pieza de acero inoxidable es empleado por lo ventajoso que resulta utilizar manillares delgados; otras características avanzadas incluyen guardas cubiertas de titanio y el cambio de la posición de los interruptores de tope.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/1995/1995.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La serie DURA-ACE 7800 es introducida al mercado.</h3>
                                <p className="text_history">
                                    Los componentes del DURA-ACE 7800 fueron desarrollados bajo el lema “para el 100% de eficiencia en la transmisión de poder” esta serie consiste en un cassette trasero de 10 velocidades, bielas HOLLOWTECH II , palancas de control dual ergonómicas y otros componentes de alto rendimiento.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2005</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>“Shimano Nordic Cycle AB”, “Shimano Nordic Cycle OY y “Shimano Nordic Cycle AS”, es establecido en la Península Escandinava.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2006</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Lianyungang) Industrial Co., Ltd. es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>ACCU-3D, highly-functional Cycling Wear es lanzado al mercado. </h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2007</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Latin America  Representacao Comerial Ltda es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>R&P Pawlak Sp. Z o. o.  es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo 2007 de STELLA es introducido al mercado.</h3>
                                <p className="text_history">
                                    El nuevo modelo que incorpora el carrete AR-C está diseñado para incrementar la distancia del lanzamiento mediante el control de la resistencia de desenrollado del carrete. Este mecanismo resulta efectivo evitando problemas con la línea al momento del lanzamiento, para prevenir la corrosión de los baleros especialmente por el agua salada, este nuevo modelo incluía los baleros antióxido “SA-RB”.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2008</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las acciones de Innovative Textiles Inc. son adquiridas.</h3>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las acciones de DashAmerica inc. (Pearl Izumi) son adquiridas.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La línea DURA-ACE 7900 es lanzada al mercado.</h3>
                                <p className="text_history">
                                    Los componentes de la serie 7900 fueron desarrolladas bajo el concepto de “evolución de la perfección”. El desviador siendo parte de estos componentes integraba el cable de cambio completamente. Las bielas consistían en el HOLLOWGRIDE en adición al HOLLOWTECH II, todos los componentes fuero renovados para un diseño optimo.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2009</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La división de Lifestyle Gear es lanzada al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2009/2009.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Rinkai Distribution Center cambia su nombre a Shimano Sales Co. Ltd.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Comienza el marketing del equipamiento de canotaje, estiradores y zapatos entran al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2009/2009-2.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3></h3>
                                <p className="text_history">
                                    Una nueva tecnología llamada inteligencia digital integrada (Di2) es introducida en la línea DURA-ACE. Las series 7900 equipadas con este sistema de cambio electrónico proveía a los competidores con una función completa “Stress-Free”.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2009/2009-3.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2010</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>XEFO es introducido al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2010/2010.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La línea XTR que incorpora el Dyna-sys entra al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2010/2010-2.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo 2010 de STELLA entra al mercado.</h3>
                                <p className="text_history">
                                    El modelo 2010 incorporaba el sistema de engranajes X-SHIP desarrollado para lograr el desempeño máximo de rotación, utilizando la ventaja del excelente procesado de metal de Shimano en específico su  tecnología de maquinado de alta precisión, nuestro equipo de producción fabricó la carcasa metálica ideal. El resultado fue una rotación aun mas suave de los engranes.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2010/2010-3.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano STEPS el primer componente para la bicicleta eléctrica es lanzado al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                </div>

                {/* 2011 */}
                <div className="row" style={{ display: 'none' }} id="2011">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2011</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Camboya) Co., Ltd. es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El Consulado General de la República de Singapur es abierto en Osaka, Japón;  en el segundo piso de las Oficinas Centrales (SIC).</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2012</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series DURA-ACE 9000 son lazadas al mercado.</h3>
                                <p className="text_history">
                                    Las series DURA-ACE 9000 son completamente repensadas en cada detalle, lo más destacable incluye bielas más robustas de 4 brazos, el nuevo sistema de frenos SLR-EV y el nuevo cassette de 11 velocidades.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2012/2012.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2013</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano (Filipinas) Inc. es establecido.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2014</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo STELLA 2014 es lanzado.</h3>
                                <p className="text_history">
                                    La tecnología de micromódulo de transmisión de engranaje de gusano reduce las vibraciones de oscilación. Este es un increíble avance en el diseño, el cual entrega un nivel sobresaliente de suavidad sin sacrificar la resistencia y la durabilidad.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2014/2014-2.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series XTR M9000, XTR M9020 son lanzadas al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2014/2014-1.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series XTR Di2 M9050 son lanzadas al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El Shimano Cycling World es abierto en Singapur.</h3>
                                <p className="text_history">
                                    Es el primer mundo interactivo y de experiencia del ciclismo en Singapur, el espacio de 650 metros cuadrados, es también la primer galería de ciclismo, está localizada a un costado del Museo de Deportes de Singapur en el Centro de Deportes de Singapur.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2014/2014-3.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La primera Sport Camera de Shimano es lanzada al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    “Shimano Menat Spor Etkinlikleri Spor Malzemeleri ve Ekipmanlari Ticaret Limited Sirketi”, es establecida en Turquía.
                                </h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    Shimano Bisiklet Parca ve Ekipmanlari Satis Servis Ticaret Anonim Sirketi es establecida en Turquía.
                                </h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    Shimano Italy Bicycle Components S.R.l  es establecido
                                </h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2015</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    HAGANE concept es lanzado al mercado.
                                </h3>
                                <p className="text_history">
                                    HAGANE es el concepto de Shimano de diseño de carretes el cual apunta a una impresionante suavidad y una abrumadora resistencia.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2015/2015-1.jpg" className="img-fluid img-thumbnail" />
                                <img src="https://www.shimano.com/jp/img/company/history/2015/2015-2.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    Shimano Uruguay es establecida.
                                </h3>

                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2015/2015-3.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>
                                    Aniversario 50 de Shimano American Co.
                                </h3>

                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2016</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Las series DURA-ACE R9100 (componentes de carrera de ruta) equipado con potenciómetro son lanzados al mercado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2016/2016-1.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>La Fábrica Inteligente Shimano Shimonoseki (SSF_IP) es completada.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2016/2016-2.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El Centro de Logística Rinkai (RLC) es completado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2016/2016-3.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2017</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Square abre en el GRAND FRONT de Osaka</h3>
                                <p className="text_history">
                                    Shimano Square abre en el GRAND FRONT de Osaka en la Ciudad de Kita-ku con el enfoque de comunicar la atracción hacia sus productos al mismo tiempo que creando y ofreciendo un nuevo estilo de vida urbano, basado en el deporte, el ciclismo y la pesca.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2017/2017.png" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2018</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Series XTR M9100 salen al mercado.</h3>
                                <p className="text_history">
                                    Persiguiendo la demanda específica para cada categoría de bicicleta de montaña.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El modelo STELLA 2018 es lanzado.</h3>
                                <p className="text_history">
                                    El nuevo STELLA de Shimano, es hasta ahora el más suave y eficiente que jamás se ha fabricado, con una durabilidad sin precedentes, la introducción del nuevo micromódulo II, con sus únicos engranes dentados y un diseño de superficie que entrega una suavidad increíble y una durabilidad superior a la ya incomparable calidad de Shimano HAGANE.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Argentina S.A.U. es establecido en Argentina.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2019</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano Experience Center abre en Valkenburg, en los Países Bajos</h3>
                                <p className="text_history">
                                    Una exhibición basada en la experiencia del ciclismo, pesca y canotaje, donde se presentan y pueden ser tocadas las últimas tecnologías de Shimano, ha abierto sus puertas en el “PAR ´ COURSE” commercial facility.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/locations/europe/eu26.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El primer grupo de componentes para GRAVEL de Shimano GRX series es lanzado al mercado.</h3>
                                <p className="text_history">
                                    Los componentes para ruta larga de gravel así como ruta sin pavimentar. Un total de 4 grupos de sets, incluyendo 3 grados basados en la forma de montar así como un Di2 Series es introducido. Incluida la característica especialmente creada para manejar en rutas sin pavimentar.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2020</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>El centro de tecnología e innovación de Shimano es completado.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano IBERIA S. L. es establecido</h3>
                            </div>
                            <div className="col-md-4 text-center">
                            </div>
                        </div>
                    </div>

                </div>

                {/* 2021 */}
                <div className="row" style={{ display: 'none' }} id="2021">
                    <div className="col-md-12">
                        <hr />
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <h2>2021</h2>
                            </div>
                            <div className="col-md-10 text-center">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano celebra su aniversario número 100.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2021/logo_shimano.svg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Taizo Shimano se convierte en el sexto presidente.</h3>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="https://www.shimano.com/jp/img/company/history/2021/President.jpg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2 text-center">
                            </div>
                            <div className="col-md-6">
                                <h3>Shimano México Bike & Fishing es establecido.</h3>
                                <p className="text_history">
                                    Shimano México Bike & Fishing es establecido en el centro de México, en Querétaro, siendo parte de la región del bajío.
                                    Como una compañía independiente, estamos 100% enfocados en desarrollar y mejorar las ventas de Shimano, sus marcas afiliadas
                                    así como de sus asociados a través del mercado Mexicano.
                                </p>
                                <p className="text_history">
                                    Nacida del excelente modelo de negocios aplicado en Brasil, brindamos el conocimiento y las prácticas para un mejor desarrollo del mercado.
                                </p>
                                <p className="text_history">
                                    Isapa y LM Bike son los líderes importadores y distribuidores de partes para bicicleta en Brasil. En 2016 se unen con Shimano para crear Blue Cycle, el distribuidor oficial de Shimano en Brasil.
                                </p>
                                <p className="text_history">
                                    Dado al excelente resultado que se tiene, así como el gran crecimiento anual y un servicio que se diferencia de todos en el mercado, una invitación es hecha, para que esta sociedad pudiera ir más allá de las fronteras del mercado Brasileño, así comienza su operación en México.
                                </p>

                            </div>
                            <div className="col-md-4 text-center">
                                <img src="./img/fachada.jpeg" className="img-fluid img-thumbnail" />
                                <img src="./img/socios.jpeg" className="img-fluid img-thumbnail" />
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <br />
            <br />
            <br />
            

        </>
    );
}

export default CompanyHistory;