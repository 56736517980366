import React, { createElement, useState } from 'react';
import { useHistory  } from 'react-router-dom';

const Unauthorized = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    function hendlerVoltarHome(){     
        setLoading(true);  
        setTimeout(() => {
            history.push("/");
        }, 4000);
    }
    return (
        <>
            <section className="conteudo_interno container">
                <div className="text-center">
                    <h1 className="display-4">Oops!</h1>
                    <p>Lo sentimos, se produjo un error con la acción realizada.</p>
                    <p>Los datos ingresados ​​para el inicio de sesión no coinciden. Comuníquese con su asesor de franquicias o registre una solicitud con Central.</p>
                    <div className="btnUnauthorized">
                        {createElement('a', { href: "/login", className: "mt-5 btn btn--cinza btn--block btn--full btn--bold" }, "Intentar nuevamente")}
                    </div>
                    <div className="btnUnauthorized">
                        <button className="mt-5 btn btn--cinza btn--block btn--full btn--bold" onClick={hendlerVoltarHome}>
                            {loading && <i className="fa fa-refresh fa-spin ml-2"></i>}Volver a la tienda
                        </button>                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default Unauthorized;