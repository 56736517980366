import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import { FaTrashAlt, FaWindowClose, faDi} from 'react-icons/fa';
import { VscDebugDisconnect } from "react-icons/vsc";
import {useCookies} from 'react-cookie';
import { disconnect } from '../../utils';


export default function ModalTeste({itIsTrue}){
    const [cookies, setCookie] = useCookies(['user']);
    const [name, setName] = useState(window.location.pathname);
    const redite = () => {
        setCookie('LinkOld', name, { path: '/' });
        setTimeout(() => {  
            disconnect()
        }, 1000); 

       
    }
   return(
                <Modal show={itIsTrue} className="modal modal-boleto fade">
                <Modal.Header>
                    <div className="modal-header_container">
                        <VscDebugDisconnect size="60"/>
                        <h2 className="titulo h2">Sesión expirada</h2>
                        <div className="textModal">
                            <p>Después de un tiempo sin actividad, la sesión terminó.
                                Inicie sesión de nuevo por favor.</p>
                        </div>
                       
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{}}>
                        <FaWindowClose/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    
                    <button type="button" className="btn btn-modal" data-dismiss="modal" aria-label="Close" onClick={redite}>
                        <span>Login</span>
                    </button>
                </Modal.Body>
            </Modal>
        
        ) 
    }
    

