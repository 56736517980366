import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

export const fetchCategories = (tokenLogin) => {
    const config = {
        headers: { Authorization: `Bearer ${tokenLogin ?? HTTP_TOKEN}`  }
    }
    const options = {
        "Esquema": "web",
        "Tabela": "Categoria"
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchCategoriesById = (tokenLogin, idClassificacao, ApenasComImagens, ExigirAssociacao) => {
    const config = {
        headers: { Authorization: `Bearer ${tokenLogin ?? HTTP_TOKEN}` }
    }
    
    const options = {
        "Esquema": "web",
        "Tabela": "Categoria",
        "Parametros": [
            { "Nome": "idClassificacao", "Valor": idClassificacao },
            { "Nome": "ApenasComImagens", "Valor": ApenasComImagens },
            { "Nome": "ExigirAssociacao", "Valor": ExigirAssociacao }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}