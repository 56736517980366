import React, { useEffect, useState } from 'react';
import { getItemSession } from '../../utils';
import img4 from './../../img/LIFESTYLE.jpg';
import img3 from './../../img/GRAVEL.jpg';
import img2 from './../../img/MTB.jpg';
import img1 from './../../img/ROAD.jpg';
import { useCookies } from 'react-cookie';

const CardBanner = () => {
    const [banners] = useState([img1, img2, img3, img4]);
    const [filialSelecionada, setfilialSelecionada] = useState(false);
    const [cookies, ] = useCookies([]);

    useEffect(() => {
        var filial = getItemSession('_filialSelecionada') ?? 0;
        var myCookieFilial = (cookies._filialSelecionada) ?? 0
        if (filial === '6' && filial === '6') {
            setfilialSelecionada(false)
        }
        else if (filial === '2' && myCookieFilial === '2') {
            setfilialSelecionada(true)
        }
    },[cookies._filialSelecionada, filialSelecionada])

   

    const bannerLinks = [
        'https://road.shimano.com/es/',
        'https://mtb.shimano.com/es/',
        'https://gravel.shimano.com/es/',
        'https://lifestylebike.shimano.com/es/'
    ];

    return (
        filialSelecionada &&
            <div className="card-banner-container">
                {banners.map((banner, index) => (
                    <a target="_blank" rel="noopener noreferrer" href={bannerLinks[index]} key={index} className="card-banner-link">
                        <div className="card-banner">
                            <img src={banner} alt={`Banner ${index}`} />
                        </div>
                    </a>
                ))}
            </div>    
    );
};
export default CardBanner;
