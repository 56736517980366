import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

export const fetchAllInvoices = (numeroPedido = null, dataInicial = null, dataFinal = null) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {
        "Esquema": "BlueCycle",
        "Tabela": "Historico",
        "Parametros": [
        ]
    }

    if (numeroPedido) {
        options.Parametros.push({ "Nome": "NumeroPedido", "Valor": numeroPedido })
    }

    if (dataInicial && dataFinal) {
        options.Parametros.push(
            {
                "Nome": "DataInicial",
                "Valor": dataInicial
            },
            {
                "Nome": "DataFinal",
                "Valor": dataFinal
            }
        )
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchInvoice = (idPedido) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {
        "Esquema": "BlueCycle",
        "Tabela": "Historico",
        "Parametros": [
            {
                "Nome": "idPedido",
                "Valor": parseInt(idPedido)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchInvoiceItems = (idPedido) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {
        "Esquema": "web",
        "Tabela": "HistoricoItem",
        "Parametros": [
            {
                "Nome": "idPedido",
                "Valor": parseInt(idPedido)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchTracking = (idPedido) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    const options = {
        "Esquema": "web",
        "Tabela": "TrackingHistorico",
        "Parametros": [
            {
                "Nome": "idPedido",
                "Valor": parseInt(idPedido)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}


export const fetchNF = (idPedido) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "NotasPedido",
        "Parametros": [
            {
                "Nome": "idPedido",
                "Valor": parseInt(idPedido)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchXML = (idPedido) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "NotasPedido",
        "Parametros": [
            {
                "Nome": "idPedido",
                "Valor": parseInt(idPedido)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDadosXML`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchExcel = (idPedido) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "HistoricoItem",
        "Parametros": [
            {
                "Nome": "idPedido",
                "Valor": parseInt(idPedido)
            }
        ]
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDadosExcel`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}


export const fetchDeliveryDate = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {
        "Esquema": "web",
        "Tabela": "DatasPedido"
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}

export const fetchHistoricoNFArquivo = (idNF) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }

    const options = {       
        "idNF":  parseInt(idNF)
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDadosHistoricoNFArquivo`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}
