import React, { useState, useEffect, createElement, useRef } from 'react';
import { fetchAllInvoices } from '../services/invoices-api';
import { formatMoney, disconnect, formatDateToView, formatDateToDatabase, setItemSession, scrollToTop } from '../utils';
import { Loader } from '../components/Loader/Loader';
import { postDuplicateCart, fetchCartItems } from '../services/cart-api';
import * as CartActions from '../store/actions/cart'
import { connect } from 'react-redux';
import { FaCalendar, FaSync } from 'react-icons/fa';

const Invoices = ({ refreshCartFn }) => {
    const [isLoading, setLoading] = useState(true);
    const [qtd, setQtd] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [inputs, setInputs] = useState({
        numeroPedido: ''
    });
    
    useEffect(() => {
        scrollToTop();
    })

    useEffect(() => {
            const currentDate = new Date();
            const initialDate = new Date(currentDate - 90 * 24 * 60 * 60 * 1000); // 90 dias    
            const  dataInicial = (initialDate.toISOString().split('T')[0]);
            const dataFinal=(currentDate.toISOString().split('T')[0]);
        
        fetchAllInvoices(inputs.numeroPedido, dataInicial, dataFinal)
            .then(result => {
                setInvoices(result.data.Data.Dados)
                setQtd(result.data.Data.QuantidadeRegistrosTotal)
                setLoading(false)
            })
            .catch(reject => {
                disconnect();
            })
    }, []);    
    
    const fetchLocal = (numeroPedido = null, dataInicial = null, dataFinal = null) => {
        setLoading(true);

        fetchAllInvoices(numeroPedido, dataInicial, dataFinal)
            .then(result => {
                setInvoices(result.data.Data.Dados)
                setQtd(result.data.Data.QuantidadeRegistrosTotal)
                setLoading(false)
            })
            .catch(reject => {
                disconnect();
            })
    }

    const handleDuplicateInvoice = (idPedido) => {
        postDuplicateCart(idPedido)
            .then(resultDup => {
                if (resultDup.data.Codigo === 200) {
                    fetchCartItems()
                        .then(result => {
                            if (result.data.Data.Dados.length > 0) {
                                setItemSession('_carrinho', JSON.stringify(result.data.Data))
                                refreshCartFn(result.data.Data);
                                window.location = '/carrinho';
                            }
                        })
                        .catch(reject => {
                            disconnect();
                        })
                } else {
                    console.debug('Error')
                }
            })
    }

    const inputDate = useRef(null);

    const onInputChange = event => {
        const { name, value } = event.target;

        setInputs({
            ...inputs,
            [name]: value.trim()
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        let inputValue = inputDate.current.value;
        let dates = inputValue.split(" a ");
    
        const dataInicial = formatDateToDatabase(dates[0]);
        const dataFinal = formatDateToDatabase(dates[1]);
    
        fetchLocal(inputs.numeroPedido, dataInicial, dataFinal);
    }
    

    return (
        <>
            
            <section className="pedidos container">
                <div className="sect-header">
                    <div className="sect-titulo justify-content-between">
                        <h2 className="titulo h2">Pedidos</h2>
                        <h6 className="titulo h6">Artículos: <span className="arial-bold">{qtd}</span></h6>
                    </div>
                    <hr />
                </div>

                <h4 className="titulo h4">Buscar</h4>
                <form className="form-pedidos" onSubmit={handleSubmit}>
                    <fieldset className="form-pedidos--wrapper">
                        <label className="input-label" htmlFor="numeroPedido">Número de pedido ERP</label>
                        <input
                            type="text"
                            className="input h6 mb-0 pt-3 pb-3"
                            placeholder="Ingresa número de pedido"
                            name="numeroPedido"
                            onChange={onInputChange}
                        />
                    </fieldset>
                    <fieldset className="form-pedidos--wrapper">
                        <label className="input-label" htmlFor="pedido-periodo">Fecha de emisión</label>
                        <label htmlFor="pedido-periodo" className="historico label-daterangepicker">
                            <FaCalendar className="h6 mr-2 mb-0" color="dodgerblue"/>
                            <span className="h6 mb-0">De</span>
                            <input
                                ref={inputDate}
                                type="text"
                                className="input-daterangepicker h6 mb-0"
                                name="daterange"
                                id="pedido-periodo"
                                readOnly="True"
                            />
                        </label>
                    </fieldset>
                    <button type="submit" className="btn btn--lg btn--cinza btn--full">Filtrar</button>
                </form>

                <h2 className="titulo h2 pedidos-titulo">Últimos pedidos</h2>
                <div className="tabela-overflow">
                    {!isLoading ? (
                        invoices?.length > 0 ? (

                            <table className="tabela tabela-listrada">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>N. Pedido ERP</th>
                                        <th>N° Factura</th>
                                        <th width="160">Fecha de Emisión</th>
                                        <th width="200">Código de rastreo</th>
                                        <th width="175">Monto del Pedido</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices.map((invoice, index) =>
                                        <tr key={index} value={invoice.idPedido}>
                                            <td>
                                                {invoice.Status}
                                            </td>
                                            <td>{!invoice.NumeroPedidoERP ? 'Procesando': invoice.NumeroPedidoERP}</td>
                                            <td>{invoice.NumeroPedido}</td>
                                            <td>{formatDateToView(invoice.DataEmissao)}</td>
                                            <td>
                                                {true
                                                    ? 'En Definición'
                                                    : formatDateToView(invoice.DataEntrega) + '-' + invoice.PeriodoEntrega}
                                            </td>
                                            <td>$ {formatMoney(invoice.Valor, 2, '.', ',')}</td>
                                            <td>
                                                {/* <a href="/" className="btn btn-link c-roxo">Ver pedido</a>  */}
                                                {createElement('a', { href: `/pedido/${invoice.idPedido}`, className: "c-roxo btn btn--link" }, <span className="fas fa-eye"></span>)}
                                                <button data-toggle="tooltip" data-placement="top" title="Repetir Pedido" className="c-roxo btn btn--link" onClick={() => { if (window.confirm('¿Quieres repetir este pedido?')) handleDuplicateInvoice(invoice.idPedido) }}><FaSync /></button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                                <div className="alert alert-warning" role="alert">
                                    No se encontraron pedidos
                                </div>
                            )

                    ) : (
                            <Loader short="false" />
                        )}

                </div>

                <div className="sect-footer align-items-start">
                    {/* <a href="/" className="btn btn--cinza btn--block btn-full btn--bold">Voltar</a> */}
                    {createElement('a', { href: '/', className: "btn btn--cinza btn--block btn-full btn--bold" }, "Regresar")}
                </div>
            </section>
            
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(Invoices);