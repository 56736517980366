import React, { useEffect, useState } from 'react';
import { scrollToTop, getItemSession } from '../utils';
import { fetchClient } from '../services/client-api';


const Politics = () => {
    const [client, setClient] = useState([]);

    useEffect(() => {
        scrollToTop();
    })
    
    const getDados = getItemSession('_dados');
    const dados = JSON.parse(getDados);


    useEffect(() => {
        fetchClient(dados.idCliente)
            .then(result => {
                setClient(result.data.Data.Dados[0])
            });
    }, [])
    
    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>Política de Garantías</h1>
                    </div>
                    {client.Suframa &&
                        <ul className='list-unstyled '>
                            <h5><b>Solicitud de Garantía</b></h5>
                            <li style={{fontSize: '14px'}}>Antes de solicitar una garantía, verifique si el producto está dentro de la política vigente, en caso de estar de acuerdo, haga <a target='_blank' rel="noopener noreferrer" href={client.Suframa}><b>click aquí para iniciar el proceso de garantía</b></a>.</li>
                        </ul>
                    }
                    <hr />
                </div>
                <div className="subtitulo-politics">
                <h4>¿Qué debo hacer al recibir mi pedido?</h4>
                    <ol>
                        <li>
                            Verifique la cantidad de cajas recibidas y las que vienen indicadas en su factura, en caso de no coincidir, comuníqueselo al transportista quien debe entregarle las cajas faltantes.
                        </li>
                        <li>
                            Nuestras cajas vienen selladas con cintas de seguridad, verifique que las cajas no vengan abiertas o con orificios laterales de ser así, notifíquelo al transportista el cual deberá esperar a que usted habrá sus cajas y verifique no halla faltantes de piezas, firme la recepción hasta que haya verificado el material y este en buenas condiciones.
                        </li>
                        <li>
                            Si las cajas vienen correctas por favor firme de recibido al transportista y verifique que la mercancía dentro de la caja coincida con las cantidades de su factura.
                        </li>
                        <li>
                            Para reportar cualquier faltante, anomalía o material dañado, favor de comunicarse a nuestro SACS (Servicio de atención a Clientes Shimano) o directamente con nuestro asesor de ventas quienes lo orientaran de los pasos a seguir
                        </li>
                    </ol>
                    Cualquier anomalía con su pedido debe de ser reportada dentro de las primeras 48 horas hábiles a la recepción de su mercancía, de lo contrario no se aceptan reclamaciones por cajas perdidas o material faltante.                
                </div>

                <div className="subtitulo-politics">
                    <h4>El plazo de 48 horas ha pasado, ¿Cómo informo de un problema?</h4>
                   <ul>
                       <li>
                        Si el problema es relacionado con daños presentados en el producto, debe ponerse en contacto con el SERVICIO ATENCION AL CLIENTE SHIMANO (SACS) para realizar el proceso de garantía del producto, y seguir sus instrucciones.
                       </li>
                       <li>
                        Si el problema es con la cantidad de unidades recibidas póngase en contacto con su asesor o con el SACS para informar del problema, quien de ser posible le ayudara lo mas que pueda, le comentamos que sea lo más rápido posible ya que se debe de verificar procesos en sistema y mientras mas tiempo pase es más difícil determinar un rastreo.
                       </li>
                   </ul>
                </div>

                <div className="subtitulo-politics">
                    <h4>¿Cómo aplico una devolución de material?</h4>
                    Solo se aceptan devoluciones en los siguientes casos:
                    <ul>
                        <li>
                            Faltante de material que se demuestre fielmente que el material no se envió en almacén.
                        </li>
                        <li>
                            Material sobrante empacado por error en mi pedido
                        </li>
                        <li>
                            Producto solicitado erróneamente por el asesor de ventas, previa confirmación con asesor
                        </li>
                        <li>
                            Material con falta de manuales o piezas, especificadas en empaque.
                        </li>
                        <li>
                            Errores de descripción en B2B, que especifique un producto y el que llegue sea otra.
                        </li>
                        <li>
                            Materiales en garantía que han sido descontinuados por Shimano.
                        </li>
                    </ul>
                    Para que su devolución sea efectiva, debe estar reportada a nuestro SAC o a su asesor de venta, dentro de las 48 horas posteriores a su entrega.<br/><br/>                    
                    Y hacer el envió de la factura de compra indicando las piezas que presentaron el problema.<br/><br/>
                    En este caso se le asignara un folio de devoluciones y se le notificara vía correo electrónico o Email, la resolución o rechazo de su folio 
                    (ver proceso anexo), en caso de que sea aceptada la devolución, se hará por medio de una nota de crédito que se aplicara a su numero de cliente, se le enviara notificación de la aprobación de la devolución con la nota de crédito correspondiente y en su caso la solicitud del material.
                </div>

                <div className="subtitulo-politics">
                    <h4>Garantías</h4>
                    Antes de recibir una garantía por parte de cliente final coméntele lo siguiente:
                    <ul>
                        <li>
                            El plazo para la resolución de una garantía es de hasta 30
                        </li>
                        <li>
                            Si se solicita la pieza para revisión quiere decir que paso por el primer paso de garantía, por lo que usted debe 
                            mandar la pieza con el numero de guía, pero si al hacer la revisión física se determina que no pasa se cobrara el costo de la guía al cliente, por lo que este punto debe especificarse concretamente al distribuidor. 
                        </li>
                        <li>
                            Si se deben de enviar requisitos completos, de lo contrario se rechazará completo el formato.
                        </li>
                        <li>
                            No se aceptan materiales que no sean amparados por una factura de Shimano bike and Fishing SA de CV. 
                        </li>
                    </ul>                   
                </div>

                <div className="subtitulo-politics">
                    <h4>¿Cómo aplico una garantía de Material?</h4>
                   <ol>
                       <li>
                        Comuníquese al SAC y reporte la falla del material o en su caso los accesorios faltantes
                       </li>
                       <li>
                        El SAC le proporcionara el documento de garantía para que lo llene correctamente y completo, 
                        debe adjuntar fotos o videos del mal funcionamiento de la pieza, así como copia o fotografía del comprobante de compra del cliente final.
                       </li>
                       <li>
                        envíe vía mail sus documentos para asignarle un numero de folio, debe enviar un documento de garantía por pieza dañada al <a>correo@shimanomexico.com</a>
                       </li>
                       <li>
                        El departamento de garantías le asignara un numero de folio de seguimiento.
                       </li>
                       <li>
                        En el momento que lo indique garantías debe enviar la pieza con el número de guía asignado para la revisión de la pieza.
                       </li>
                       <li>
                        En caso de que su garantía sea aprobada y tengamos stock en almacén, se le enviara la pieza con el número de guía asignado para 
                        que haga la entrega a cliente final, en caso de que la pieza no tenga existencia , se le dará la fecha aproximada de arribo a planta
                       </li>
                       <li>
                        En caso de rechazo se le notificara via correo de los motivos de rechazo para consideración del cliente
                       </li>
                   </ol>
                </div>
                
            </section>
            
        </>
    );
}

export default Politics;