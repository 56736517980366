import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

export const fetchStates = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    
    const options = {}

    return new Promise((resolve, reject) => {
        const ret = http.post(`FaleConosco/ObterEstados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}


export const fetchSubjects = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    
    const options = {}

    return new Promise((resolve, reject) => {
        const ret = http.post(`FaleConosco/ObterAssuntos`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}


export const postContact = (RazaoSocial, Responsavel, Telefone, Email, Assunto, Mensagem) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    
    const options = {
        "Esquema": "web",
        "RazaoSocial": RazaoSocial,
        "Responsavel": Responsavel,
        "Telefone": Telefone,
        "Email": Email,
        "Assunto": Assunto,
        "Mensagem": Mensagem
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`FaleConosco/EnviarEmail`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}


export const registrarAviseMe = (idProduto, Email) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    
    const options = {
        "idProduto": idProduto,
        "Email": Email
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`AviseMe/Registrar`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}
export const validarAviseMe = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}` }
    }
    
    const options = {
        "Esquema": "web",
        "Tabela": "AviseMeEmail",
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);
    })
}
