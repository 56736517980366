import React, { createElement } from 'react';
import ProductCard from '../Product/ProductCard';
import { arrOrigin } from '../../constants';

// Config carousel
import OwlCarousel from 'react-owl-carousel';

const options = {
    loop:true, 
    items:4, 
    nav:true, 
    autoplay:true, 
    autoplayHoverPause:true,
    navText: ['',''],
    navClass:['nav-btn prev-slide','nav-btn next-slide'],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        }
    },
};

const BestSeller = ({ destaque }) => {
    return (
        <>
            <div className="justify-content-between align-items-center div_titulo">
                <h4 className="titulo h4 titulo_new">Productos Increíbles</h4>
                {/* <a href="/destaque/revista" className="link-texto text-decoration-none mr-md-3">ver todas</a> */}
                {createElement('a', { href: "/destaque/Vitrine4", className: "link-texto btn_view text-decoration-none mr-md-3" }, "ver todo")}
            </div>
            <div className="overflow-auto">
                <div className="cards cards-produto cards-produto-width-4 d-flex justify-content-between align-items-center">
                    { destaque.length  > 0 && <OwlCarousel 
                        className="owl-theme" 
                        {...options}
                    >
                        {destaque.map(product =>
                            <ProductCard
                                key={`bestseller-card-${product.idProduto}`}
                                product={product}
                                origin={arrOrigin['home_revista']}
                            />
                        )}
                    </OwlCarousel>
                    }
                </div>
            </div>
        </>
    );
}

export default BestSeller;