import React, {  useEffect  } from 'react';


import { scrollToTop } from '../utils';

const Company = () => {
    
    useEffect(() => {
        scrollToTop();
    })

    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>La Empresa</h1>
                    </div>
                    <hr />
                </div>
                
            </section>
            
        </>
    );
}

export default Company;