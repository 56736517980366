import React, { createElement } from 'react';
import { formatMoney } from '../../../utils';

const CartResponsive = ({ items }) => {
  
    return (
        <>
            <h4 className="titulo h4">Seu carrinho</h4>
            {items?.QuantidadeRegistrosRetornados > 0 ?
                <>
                    <div className="cart_list">
                        <ul className="list-unstyled mb-0 cart-responsive">
                            {items.Dados.map((item, index) => (
                                <li key={`cart-dropdown-resp-item-${index}`} className="cart_list_item">
                                    {createElement('a', { href: `/produto/${item.idProduto}`, className: 'dropdown-menu_link' }, item.Descricao)}
                                    <div className="cart_list_item_footer">
                                        <p>Cantidad: {item.Quantidade}</p>
                                        <p>$ {formatMoney(item.ValorTotalFinal, 2, '.', ',')}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="header_nav_cont_footer">
                        {createElement('a', { href: '/carrinho', className: 'btn btn--cinza btn--full mt-3' }, 'Ver Carrito')}
                    </div>
                </> 
            :
                <div className="cart_list">
                    Carrinho vazio
                </div>
            }
        </>
    )
}
export default CartResponsive;