import React, {  useEffect  } from 'react';
// default
import { scrollToTop } from '../utils';

const Politics = () => {

    useEffect(() => {
        scrollToTop();
    })
    
    return (
        <>
            
            <section className="fale-conosco container">
                <div className="sect-header">
                    <div className="sect-titulo">
                        <h1>Política Comercial</h1>
                    </div>
                    <hr />
                </div>
                <div className="subtitulo-politics">
                <h4>OBJETIVO</h4>
                Este documento tiene como objetivo establecer las reglas básicas de Shimano México para definir la estrategia de comercialización. 
                Esta política es un área fundamental para el correcto funcionamiento de la empresa ya que de ella dependerán directamente los resultados comerciales.
                </div>
                <div className="subtitulo-politics">
                <span>1. Términos de pago (plazo):</span><br/><br/>
                Se tomará como base los plazos históricos que los clientes han tenido con la marca Shimano. Quedando los términos de pago de la siguiente manera:
                <ul>
                    <li>
                    <b>Contado:</b> Este plazo aplicara para nuevos clientes, así como para aquellos que no tengan un historial crediticio con la marca. Para nuevos clientes se manejará un plazo de contado de 3 meses antes de pasar a un plazo de 30 días.
                    </li>
                    <li>
                    <b>30 días:</b> Clientes que tengan ya un histórico sano financieramente de dicho termino con la marca.
                    </li>
                    <li>
                    <b>60 días:</b> Los clientes que históricamente ya tienen 60 días de plazo deberán empezar en un inicio con 30 días de plazo y posterior a un periodo de 3 meses de pagos puntuales tendrán acceso a su plazo de 60 días.
                    </li>
                    <li>
                    <b>15/30/45:</b> Clientes con plazo de 30 días, tendrán acceso a un plan de pagos con este término en pedidos mayores a $20,000.
                    </li>
                    <li>
                    <b>30/60/90:</b> Clientes con plazo de 60 días, tendrán acceso a un plan de pagos con este término en pedidos mayores a $20,000.
                    </li>
                </ul>
                <div className="subtitulo-politics">
                Los clientes con 30 y 60 días de plazo tendrán un 1% de bonificación vía descuento en su siguiente factura si el pago de esta es realizado dentro de su plazo. Adicionalmente los clientes que cuenten con crédito de 30 o 60 días y opten por pagar su pedido de contado, tendrán un descuento adicional del 2%.<br/><br/>
                Los clientes con retraso de pago en su fecha de vencimiento entraran en un <b>semáforo rojo</b>, dando como resultado el congelamiento de su cuenta hasta no recibir el 100% del pago pendiente. Es decir, por ninguna razón se le podrán surtir pedidos una vez que entre en este estatus. 
                A partir del día 1 de retraso, se tendrá una multa equivalente al 5%mensual (prorrateado) del total de la deuda. Del mismo modo su límite de crédito será disminuido en un 50% y deberá pasar un periodo de 3 meses para poder acceder a su límite de crédito inicial. 
                Si el retraso es mayor a 8 semanas, se perderá el 100% del límite de crédito y el cliente pasará a un esquema de contado. 
                </div>
                </div>
                <div className="subtitulo-politics">
                    <span>2. Límite de crédito:</span><br/><br/>
                    Sin excepción, todos los clientes con derecho a plazo deberán ser previamente documentados con un pagare que respalde legalmente el valor de su crédito.
                    Los clientes interesados en incrementar su límite de crédito tendrán que mostrar un histórico de 3 meses de pago en tiempo para poder tener acceso a un incremento de 20% sobre su monto crédito actual. 
                    Idealmente el cliente deberá presentar comprobantes de límite de crédito con otros proveedores y de ser posible sus estados financieros como soporte. Todos los incrementos de crédito deberán ser aprobados por Shimano México y en algunos casos puede no aplicar el incremento si la compañía así lo decide. 
                <br/><br/>

                </div>

                <div className="subtitulo-politics">
                <span>3. Canales de distribución y descuentos (lista de precios):</span><br/><br/>
                Los canales de distribución establecidos en un inicio son los enlistados a continuación. 
                Por estrategia no está permitida la venta a otros canales que no estén enlistados:
                <ul>
                    <li>
                    <b>Tiendas Boutique:</b> Son tiendas que se caracterizar por manejar un line up de muy alta gama. 
                        Por lo general se caracterizan por comercializar solamente 1 o 2 marcas de bicicletas con alto valor comercial (TREK, SPECIALIZED, CANNONDALE, SCOTT, etc.)
                    </li>
                    <li>
                    <b>IBDs: </b> Tiendas de ciclismo con multimarca en sus pisos de venta.
                    </li>
                    <li>
                    <b>Tiendas Departamentales y Sporting Goods:</b> Son tiendas especializas en deportes o con un departamento especializado en deportes. Ejemplo Marti, Liverpool, Sears, Decathlon, Palacio de Hierro, etc. 
                    </li>
                    <li>
                    <b>E-commerce:</b> Son clientes cuya venta pura es online y no cuentan con una tienda física. Se excluyen de este esquema personas físicas o morales que tengan venta en market place(Mercado Libre, Amazon, Linio, etc..) sin un proyecto de comercialización establecido.
                    </li>
                    <li>
                    <b>Mayoristas:</b> Son clientes que cuentan con herramientas logísticas para su propia distribución llegando a puntos de venta y locaciones que por el momento no son estratégicamente parte del canal de distribución de Shimano México.
                    </li>
                    <li>
                    <b>OEM:</b> Son clientes que tienen una operación de armado de bicicletas dentro del país.
                    </li>
                    <li>
                    <b>Talleres de ciclismo:</b> Son aquellos establecimientos especializados en el mantenimiento o reparación de bicicletas.
                    </li>
                </ul>
               
                </div>
               
                <div className="subtitulo-politics">
                    <span>4. Descuentos:</span><br/><br/>
                    Shimano México empezará operaciones manejando solamente 1 lista de precio la cual incluirá un precio de lista sugerido (MSRP). 
                    Sin embargo, por cuestiones estratégicas se manejarán 3 tipos de descuentos sobre factura:<br/><br/>

                    <ul>
                        <li>
                        <b>0%:</b> Clientes con facturación debajo de $10,000 pesos mensuales.
                        </li>
                        <li>
                        <b>2%:</b> Clientes que tengan una facturación promedio por arriba de $10,000 y hasta $29,999 pesos mensuales.
                        </li>
                        <li>
                        <b>4%:</b> Clientes que tengan una facturación promedio por arriba de $30,000 y hasta $59,999 pesos mensuales.
                        </li>
                        <li>
                        <b>6%:</b> Clientes que tengan una facturación promedio por arriba de $60,000 y hasta $99,999 pesos mensuales.
                        </li>
                        <li>
                        <b>8%:</b>  Clientes estratégicos que tengan una facturación promedio por arriba de $100,000 y hasta $299,999 
                        pesos mensuales y que dentro de su operación necesiten una diferenciación en precio o inversión para su crecimiento.
                        </li>
                        <li>
                        <b>12%:</b> Clientes estratégicos que tengan una facturación promedio por arriba de $300,000 pesos mensuales y que dentro de su operación necesiten una diferenciación en precio o inversión para su crecimiento.
                        </li>
                    </ul>
                    <b>OEM:</b> Clientes que tengan y puedan comprobar una operación de armado dentro del país. Es importante recalcar que este descuento es exclusivamente para materiales con uso de armado de bicicletas y no para venta como aftermarket.<br/><br/>
                    <b>Bikefitting:</b> Aplica este descuento a aquellos clientes que por estrategia de la marca sean parte del esquema Bikefitting. <br/><br/>
                    <b>*Todos los descuentos deberán tener una previa autorización de la Gerencia Comercial.</b><br/><br/>
                    En los inicios de la operación, estos descuentos serán otorgados basados en el histórico de compra de la marca. 
                    Se tendrá una revisión trimestral del monto facturado para tomar decisiones de incremento o decremento de los descuentos. <br/><br/>
                </div>

                <div className="subtitulo-politics">
                    <span>5. Mínimo de compra y envíos</span><br/><br/>
                    En un inicio de la operación se establecerá un mínimo de compra de $2,000. 
                    El monto mínimo de pedido para ser acreedor al envió gratis es de $5,000 pesos. 
                    En caso de que el pedido sea menor a la cantidad indicada, se tendrá un cargo de $200 pesos por envió.<br/><br/>
                </div>

                <div className="subtitulo-politics">
                    <span>6. Documentos para alta</span><br/><br/>
                    A todos los clientes se les pedirá que nos haga llegar los siguientes documentos: 
                    Copia de RFC, comprobante de domicilio, acta constitutiva, pagare firmado por el apoderado legal de la Razón Social a dar de alta, 
                    número de cuenta bancaria (clabe)<br/><br/>
                </div>

                <div className="subtitulo-politics">
                    <span>7. Métodos de pago:</span><br/><br/>
                    Todas las facturas deberán ser liquidadas vía transferencia electrónica o deposito en ventanilla de bancos, <b>no se aceptan pagos en efectivo o cheque</b>. 
                    Del mismo modo es <b>indispensable y riguroso</b> que en el número de referencia de la transferencia o deposito, se incluya el <b>número de factura</b> al cual refiere la transacción. 
                    De no incluir esta referencia, Shimano México no podrá asociar el pago a una factura y el historial crediticio del cliente será afectado. 
                </div>
                <div className="subtitulo-politics">
                    <span>8. Facturacion:</span><br/><br/>
                    Shimano Mexico no garantiza que los pedidos ingresados o pagados (en el caso de los pedidos de contado los cuales deben ser pagados anticipadamente previo a su facturacion y envió) 
                    sean facturados dentro del mismo mes en curso. Lógicamente siempre se tratará de facturar y enviar los pedidos dentro del mismo mes en el que son recibidos, sin embargo en los <b>últimos 3 días
                    del mes</b> debido a la carga de pedidos no garantizamos esto pueda ser siempre posible.<br/><br/>

                    Todos los clientes deberán conocer sus condiciones comerciales por escrito. Por lo tanto, es necesario que todos nuestros clientes entiendan y firmen las políticas comerciales.
                </div>
            </section>
            
        </>
    );
}

export default Politics;