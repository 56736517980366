import React, { useState, useEffect, createElement } from 'react';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import Modal from "react-bootstrap/Modal";
import { fetchClient, fetchEnderecos } from '../services/client-api';
import { fetchCart, fetchPaymentTerms, postSelectedPaymentTerm, postSelectedListItem, postEndCart, postSurveyRate, fetchCartItems, postSelectedEndereco, fetchListItem, fetchCupomDesconto, postAplicarCupom } from '../services/cart-api';
import { fetchDeliveryDate } from '../services/invoices-api';
import { formatMoney, disconnect, formatDateToView, scrollToTop, setItemSession, getItemSession } from '../utils';
import * as CartActions from '../store/actions/cart'
import { connect } from 'react-redux';
import { Loader } from '../components/Loader/Loader';
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

const Checkout = ({ refreshCartFn }) => {
    const [client, setClient] = useState([]);
    const [invoice, setInvoice] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [lastPaymentTerm, setLastPaymentTerm] = useState([]);
    const [listaItemCFDI, setListaItemCFDI] = useState([]);
    const [confirmScreen, setConfirmScreen] = useState(false);
    const [inputs, setInputs] = useState({ idCondicaoPagamento: '' });
    const [inputsIdListaItem, setInputsIdListaItem] = useState({ idListaItem: '' });
    const [inputEndereco, setInputEndereco] = useState({ idEndereco: '' });
    const [deliveryDate, setDeliveryDate] = useState('');
    const [errors, setErrors] = useState([]);
    const [ratingOldInvoice, setRatingOldInvoice] = useState(null);
    const [rating, setRating] = useState(0);
    const [ratingObs, setRatingObs] = useState(null);
    const [enderecos, setEnderecos] = useState([]);
    const [cupomDescontos, setCupomDescontos] = useState([]);
    const [spinnerLoad, setSpinnerLoad] = useState(false);
    // modal
    const [isOpenFrete, setIsOpenFrete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [descontoCupom, setDescontoCupom] = useState(0);
    const [valorDescontoCupom, setValorDescontoCupom] = useState(0);

    ReactGA.pageview(window.location.pathname + window.location.search)

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const hideModalFrete = () => {
        setIsOpenFrete(false);
    };
    // fim modal

    const getDados = getItemSession('_dados');
    const dados = JSON.parse(getDados);

    const pedido = getItemSession('_pedido');
    const listaCFDI = 'CFDI';
    const history = useHistory();

    useEffect(() => {
        fetchCartItems()
            .then(result => {
                const qdtEstoque = result.data.Data.Dados.filter(newarr => newarr.Estoque < newarr.Quantidade);

                if (qdtEstoque.length > 0) {
                    alert("Validar los artículos");
                    history.push("/carrinho");
                }

            })
            .then(result => setLoading(false))
            .catch(reject => {
                console.log(reject, 'fetchCartItems')
            })
    }, [])


    useEffect(() => {
        fetchClient(dados.idCliente)
            .then(result => {
                setClient(result.data.Data.Dados[0])
            });
    }, [])

    useEffect(() => {
        fetchEnderecos()
            .then(result => {
                var endereco = result.data.Data.Dados;
                setEnderecos(endereco)

            })
    }, [])

    useEffect(() => {
        consultarCupom();
        setValorDescontoCupom(invoice.ValorDescontoCupom);
        setDescontoCupom(invoice.DescontoCupom);;
    }, [invoice])

    const consultarCupom = () => {
        fetchCupomDesconto()
            .then(result => {
                var cupomDesconto = result.data.Data?.Dados;
                if (cupomDesconto)
                    setCupomDescontos(cupomDesconto)

            })
    }

    useEffect(() => { ReactGA.pageview(window.location.pathname + window.location.search) }, [])

    useEffect(() => {
        fetchCart(pedido)
            .then(result => {
                setInvoice(result.data.Data?.Dados[0])
                if (result.data.Data.Dados[0].NumeroItens === 0) {
                    window.location.href = process.env.REACT_APP_BASE_URL
                }

                if (result.data.Data.Dados[0].Valor < 5800) {
                    setIsOpenFrete(true)
                }

                if (result.data.Data.Dados[0].idCondicaoPagamento > 0) {
                    setInputs({
                        ...inputs,
                        'idCondicaoPagamento': result.data.Data.Dados[0].idCondicaoPagamento
                    });

                }
                if (result.data.Data.Dados[0].idListaItem > 0) {
                    setInputsIdListaItem({
                        ...inputsIdListaItem,
                        'idListaItem': result.data.Data.Dados[0].idListaItem
                    });

                }
                if (result.data.Data.Dados[0].idEndereco > 0) {
                    setInputEndereco({
                        ...inputEndereco,
                        'idEndereco': result.data.Data.Dados[0].idEndereco
                    });
                }

                var descontoCupom = result.data.Data.Dados[0].DescontoCupom;
                var valorDescontoCupom = result.data.Data.Dados[0].ValorDescontoCupom;

                if (descontoCupom === null || descontoCupom === undefined) {
                    descontoCupom = 0;
                    setDescontoCupom(descontoCupom);
                }

                if (valorDescontoCupom === null || valorDescontoCupom === undefined) {
                    valorDescontoCupom = 0;
                    setValorDescontoCupom(valorDescontoCupom);
                }
                setRatingOldInvoice(pedido)
            })
            .catch(reject => {
                console.error(reject);
            })

    }, [])

    useEffect(() => {
        fetchPaymentTerms()
            .then(result => {
                setPaymentTerms(result.data.Data.Dados)

                const last = result.data.Data.Dados.filter(paymentTerm => paymentTerm.UltimaUtilizada === true)

                setLastPaymentTerm(last[0])
            })
    }, [])

    useEffect(() => {
        fetchListItem(listaCFDI)
            .then(result => {
                setListaItemCFDI(result.data.Data.Dados)
            })
    }, [])

    useEffect(() => {
        fetchDeliveryDate()
            .then(result => {
                setDeliveryDate(result.data.Data.Dados[0])
            })
        /* Deixado somente 1 disconnect */
    }, [])

    const handlePaymentTerm = (e) => {
        e.preventDefault();

        scrollToTop();

        setLoading(true);

        postEndCart()
            .then(resultEndCart => {
                if (resultEndCart.data.Codigo === 400 && resultEndCart.data.CodigoInterno === 18) {
                    setErrors(resultEndCart.data.Data.Resultados);
                    setLoading(false);
                } else {
                    const novoToken = resultEndCart.data.Data;
                    if (novoToken != null) {
                        setItemSession('_token', novoToken);
                        setItemSession('auth_token', novoToken);
                        fetchCartItems(novoToken).then(newCard => {
                            setItemSession('_carrinho', JSON.stringify(newCard.data.Data));

                        });
                    }

                    //setItemSession('_pedido', resultEndCart.data.Data.Pedido.ukPedido);

                    const newStorage = {
                        "QuantidadeRegistrosTotal": 0,
                        "QuantidadeRegistrosRetornados": 0,
                        "Paginas": 1,
                        "ResultadosPorPagina": 0,
                        "Dados": []
                    }
                    setItemSession('_carrinho', JSON.stringify(newStorage));
                    refreshCartFn(newStorage);

                    setLoading(false);
                    setConfirmScreen(true);

                    if (invoice.RealizarPesquisaSatisfacao) {
                        showModal();
                    }
                }
            })
            .catch(reject => {
                //disconnect();
                console.log(reject, 'post cart ')
            })
    }

    const onInputChangeCondicaoPagamento = event => {
        const { name, value } = event.target;

        setInputs({
            ...inputs,
            [name]: value
        });

        if (event.target.value !== '') {

            postSelectedPaymentTerm(value)
                .then(result => {
                    var pedido = result.data.Data.Pedido;
                    pedido.idCondicaoPagamento = parseInt(inputs.idCondicaoPagamento);

                    result = result.data.Data.Itens;
                    result = {
                        "QuantidadeRegistrosTotal": result.length,
                        "QuantidadeRegistrosRetornados": result.length,
                        "Paginas": 1,
                        "ResultadosPorPagina": result.length,
                        "Dados": result
                    };
                    setItemSession('_carrinho', JSON.stringify(result));
                    refreshCartFn(result);

                    setInvoice(pedido);
                });

        }


    };

    const onInputChangeEndereco = event => {
        const { name, value } = event.target;

        setInputEndereco({
            ...inputEndereco,
            [name]: value
        });

        if (event.target.value !== '') {
            postSelectedEndereco(value)
                .then(result => {
                    var pedido = result.data.Data.Pedido;
                    pedido.idEndereco = parseInt(inputEndereco.idEndereco);

                    result = result.data.Data.Itens;
                    result = {
                        "QuantidadeRegistrosTotal": result.length,
                        "QuantidadeRegistrosRetornados": result.length,
                        "Paginas": 1,
                        "ResultadosPorPagina": result.length,
                        "Dados": result
                    };
                    setItemSession('_carrinho', JSON.stringify(result));
                    refreshCartFn(result);

                    setInvoice(pedido);
                });
        }

    };

    const onInputChangeListaItem = event => {
        const { name, value } = event.target;

        setInputsIdListaItem({
            ...setInputsIdListaItem,
            [name]: value
        });

        if (event.target.value !== '') {
            postSelectedListItem(value)
                .then(result => {
                    var pedido = result.data.Data.Pedido;
                    pedido.idListaItem = parseInt(inputsIdListaItem.idListaItem);


                    result = result.data.Data.Itens;
                    result = {
                        "QuantidadeRegistrosTotal": result.length,
                        "QuantidadeRegistrosRetornados": result.length,
                        "Paginas": 1,
                        "ResultadosPorPagina": result.length,
                        "Dados": result
                    };
                    setItemSession('_carrinho', JSON.stringify(result));
                    refreshCartFn(result);

                    setInvoice(pedido);
                });
        }


    };

    const handleRate = ({ rating }) => {
        setRating(rating)
    }

    const onTextareaRateChange = event => {
        setRatingObs(event.target.value);
    };

    const handleStateCupom = (index) => {
        let dados = cupomDescontos;

        dados[index].Aplicado = !dados[index].Aplicado;
        setCupomDescontos(dados);
    };

    const aplicarCupom = (index, idPromocao) => {
        if (idPromocao > 1) {
            setSpinnerLoad(true)
            postAplicarCupom(idPromocao)
                .then(result => {
                    if (result.data.Codigo === 500) {
                        if (result.data.CodigoInterno === 4) {
                            alert(result.data.Status);
                        }
                    } else {
                        var pedidoAtualizado = result.data.Data.Pedido;
                        setInvoice(pedidoAtualizado);
                    }
                    handleStateCupom(index);
                    setSpinnerLoad(false)
                })

        }
    }

    const handleSubmitRate = (e) => {
        e.preventDefault();
        postSurveyRate(ratingOldInvoice, rating, ratingObs)
            .then(result => {
                hideModal()
            })
            .catch(reject => {
                disconnect();
            })
    }

    return (
        <>
            
            <section className="container checkout">
                <h2 className="titulo h2">Cierre de orden</h2>
                <div className="checkout--container">
                    {!isLoading ? (
                        !confirmScreen &&
                        <div className="checkout--wrapper">
                            {errors.length > 0 &&
                                <div className="alert alert-danger" role="alert">
                                    <h4 className="alert-heading">¡Atención, su pedido no ha sido generado!</h4>
                                    <p>Compruebe los errores que se enumeran a continuación:</p>
                                    <hr />
                                    <ul>
                                        {errors.map(error => (<li className="mb-0">{error.Mensagem}</li>))}
                                    </ul>
                                    {createElement('a', { href: '/carrinho', className: 'link-texto' }, 'Volver al carrito')}
                                </div>
                            }

                            <div className="checkout--campo">
                                <h4 className="titulo h4">Ingresos</h4>
                                <p className="checkout--texto">Cobrar a {client.RazaoSocial}</p>
                            </div>
                            <div className="checkout--campo">
                                <h4 className="titulo h4">Entrega</h4>
                                <p className="checkout--texto">Dirección de entrega</p>
                                <form action="">
                                    <fieldset className="form-group">
                                        <select className="form-control" onChange={onInputChangeEndereco} required name="idEndereco" id="select-checkout" >
                                            <option value="">Seleccione la direccion</option>
                                            {enderecos.map((endereco, index) => (
                                                <option
                                                    key={`endereco-${index}`}
                                                    value={endereco.idEndereco}
                                                    selected={endereco.idEndereco === inputEndereco.idEndereco}
                                                >{endereco.EnderecoCompleto}</option>
                                            ))}
                                        </select>
                                    </fieldset>
                                </form>

                                <p className="checkout--texto titulo--bold">Fecha de Entrega Estimada: {' '}
                                    {!deliveryDate.DataEntrega || deliveryDate.DataEntrega === null
                                        ? 'En definición'
                                        : formatDateToView(deliveryDate.DataEntrega)}
                                </p>
                            </div>


                            <div className="checkout--campo">
                                <h4 className="titulo h4">Pago</h4>
                                <form action="">
                                    <fieldset className="form-group">
                                        <label className="checkout--label" htmlFor="select-checkout">Condición de Pago</label>
                                        <select className="form-control" onChange={onInputChangeCondicaoPagamento} required name="idCondicaoPagamento" id="select-checkout">
                                            <option value="">Elija el plazo de pago</option>
                                            {paymentTerms.map((paymentTerm, index) => (
                                                <option
                                                    key={`payment-${index}`}
                                                    value={paymentTerm.idCondicaoPagamento}
                                                    selected={paymentTerm.idCondicaoPagamento === inputs.idCondicaoPagamento}
                                                >{paymentTerm.nParcelas}x ({paymentTerm.Descricao})</option>
                                            ))}
                                        </select>
                                    </fieldset>
                                </form>
                                <p className="checkout--texto checkout--texto-icon">
                                    {lastPaymentTerm &&
                                        <>
                                            <img src="./img/icone-info.svg" alt="" />
                                            <span>Última condición utilizada | <b>{lastPaymentTerm.Descricao}</b></span>
                                        </>
                                    }
                                </p>
                            </div>
                            <div className="checkout--campo">
                                <h4 className="titulo h4">CFDI</h4>
                                <form action="">
                                    <fieldset className="form-group">
                                        <label className="checkout--label" htmlFor="select-checkout">CFDI</label>
                                        <select className="form-control" onChange={onInputChangeListaItem} required name="idListaItem" id="select-checkout">
                                            <option value="">Seleccione el CFDI</option>
                                            {listaItemCFDI.map((itemCFDI, index) => (
                                                <option
                                                    key={`payment-${index}`}
                                                    value={itemCFDI.idListaItem}
                                                    selected={itemCFDI.idListaItem === inputsIdListaItem.idListaItem}
                                                >{itemCFDI.Descricao}</option>
                                            ))}
                                        </select>
                                    </fieldset>
                                </form>
                            </div>
                            <div>
                                {!isLoading ? (
                                    cupomDescontos.length > 0 ? (
                                        <div>

                                            <h4 className="titulo h4">Cupón</h4>
                                            <div className='div-group-button'>
                                                {cupomDescontos.map((item, index) => (


                                                    <button
                                                        type="button"
                                                        name="btn-item"
                                                        className={item.Aplicado ? "btn btn--azul-m10" : "btn btn--cinza-m10"}
                                                        onClick={() => aplicarCupom(index, item.idPromocao)}>${item.Descricao}</button>

                                                ))}
                                            </div>

                                            <br />
                                        </div>
                                    ) : (
                                        <span></span>
                                    )
                                ) : (
                                    <Loader short="false" />
                                )}
                            </div>
                            <div className="checkout--campo">
                                <h4 className="titulo h4">Cierre</h4>
                                <div className="checkout--container_valor">
                                    <div className="checkout--container_valor_fixo">
                                        <p className="checkout--texto_valor">Valor Total <span className="checkout--valor">$ {formatMoney(invoice?.Valor, 2, '.', ',')}</span></p>
                                        <div>
                                            {!spinnerLoad ?
                                                (
                                                    <>
                                                        <p className="checkout--texto_valor">Total Cupón <span className="checkout--valor">$ {formatMoney(descontoCupom, 2, '.', ',')}</span></p>
                                                        <p className="checkout--texto_valor">Total con Descuento <span className="checkout--valor">$ {formatMoney(valorDescontoCupom, 2, '.', ',')}</span></p>
                                                    </>
                                                ) : (
                                                    <Loader short="false" />
                                                )}
                                        </div>
                                    </div>
                                    {inputs.idCondicaoPagamento > 0 && inputEndereco.idEndereco !== '' && inputsIdListaItem.idListaItem > 0
                                        ? <button data-toggle="modal" data-target="#modalCheckout" className="btn btn--cinza">Revisa</button>
                                        : <button onClick={() => alert("Revisa los campos")} className="btn btn--cinza">Revisa</button>
                                    }
                                </div>
                            </div>
                        </div>

                    ) : (
                        <Loader short="false" />
                    )}

                    {confirmScreen &&
                        <div className="checkout--sucesso">
                            <span className="icobutton">
                                <span className="icon icon-check"></span>
                            </span>

                            <h2 className="titulo h2 text-center">¡Tu pedido ha sido completado <br /> con éxito!</h2>

                            {createElement('a', { href: '/pedidos', className: 'btn btn--lg btn--roxo btn--bold' }, 'Ver todos sus pedidos')}
                        </div>
                    }
                </div>
                <div className="text-center">
                    {createElement('a', { href: '/carrinho', className: 'link-texto' }, 'Volver al carrito')}
                </div>
            </section>

            {/* <!-- Modal --> */}
            <Modal show={isOpen} className="modal modal-rate fade">
                <Modal.Body>
                    <div className="text-center">
                        <form onSubmit={handleSubmitRate}>
                            <h4>¿Qué tan satisfecho está con la experiencia de compra?</h4>
                            <Rater
                                total={5}
                                rating={rating}
                                onRate={handleRate}
                            />
                            <br /><br />
                            Para cualquier observación, complete el campo a continuación <small>(opcional)</small>:
                            <textarea onChange={onTextareaRateChange} name="obs" className="input textarea" id="obs" rows="3"></textarea>
                            <br />
                            <button type="submit" className="btn btn--cinza btn--full">Enviar</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>



            {/* <!-- Modal --> */}
            <Modal show={isOpenFrete} className="modal modal-rate fade">
                <Modal.Header>
                    <div className="text-center">
                        {/* <h2 className="titulo h2">Existe(m) produto(s) em seu carrinho!</h2> */}
                        <h2 className="text-center titulo h2">Política de Envío</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <div className="text-center">
                            <h5 small>El pedido está con el monto inferior a política de envío gratis ($5,800 MXN), en cual si genera un cobro adicional de $200 MXN + IVA.</h5>
                        </div>
                        <Link type="button" className="btn-primary btn-sm btn-modal-home" to="/">Más elementos</Link>{' '}
                        <button type="button" className="btn-warning btn-sm btn-modal-home" onClick={() => hideModalFrete()}>Continuar</button>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="modal modal-checkout fade" id="modalCheckout" tabIndex="-1" role="dialog" aria-labelledby="modalCheckout" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="icon icon-times"></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2 className="titulo h2 text-center">¿Revisa?</h2>
                            <p>¿Quieres finalizar tu número de pedido - {invoice.NumeroPedido}?</p>
                        </div>
                        <div className="modal-footer">
                            <button onClick={handlePaymentTerm} name="btn-checkout" data-dismiss="modal" aria-label="Close" className="btn btn--lg btn--cinza btn--bold">¡Sí, termina!</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
}
const mapDispatchToProps = dispatch => ({
    refreshCartFn: (data) => dispatch(CartActions.refreshCart(data))
})

export default connect('', mapDispatchToProps)(Checkout);