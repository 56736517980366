import React from 'react';
import '../../scss/common.scss';
import '../../scss/tipbar.scss';
import { FaBoxOpen, FaShieldAlt, FaTruck, FaPhoneAlt } from 'react-icons/fa'

const TipBar = () => {
return (
<>
  <div className="box">
    <div className="container">
      <div className="blkTipBox">
        <div className="row">
          <div className="col-md-3 col-12 d-flex flex-row align-items-center flex-wrap justify-content-center">
            <div className="tipBar">
              <div className="tipBar__icon"><FaBoxOpen color="dodgerblue" /></div>
              <div className="tipBar__texts">
                <p><strong>Envio Gratis</strong></p>
                <p>Desde $5,000.00 + IVA</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 d-flex flex-row align-items-center flex-wrap justify-content-center">
            <div className="tipBar">
              <div className="tipBar__icon"><FaShieldAlt color="dodgerblue" /></div>
              <div className="tipBar__texts">
                <p><strong>100% Seguro</strong></p>
                <p>Entrega garantizada</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 d-flex flex-row align-items-center flex-wrap justify-content-center">
            <div className="tipBar">
              <div className="tipBar__icon"><FaTruck color="dodgerblue" /></div>
              <div className="tipBar__texts">
                <p><strong>Entrega Rápida</strong></p>
                <p>Velocida de Entrega</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 d-flex flex-row align-items-center flex-wrap justify-content-center">
            <div className="tipBar">
              <div className="tipBar__icon"><FaPhoneAlt color="dodgerblue" /></div>
              <div className="tipBar__texts">
                <p><strong>Contacto a Distribuidores</strong></p>
                <p>+52 1 442 582 0239</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
);
}

export default TipBar;