import { http, isExpired, HTTP_TOKEN, REJECT_MSG } from './http';

export const fetchClientSSC = () => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}`  }
    }
    const options = {
        "Esquema": "web",
        "Tabela": "ClienteServiceCenter"
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);

    })
}

export const fetchCidadesSelecionadas = (cidadeValue = null) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}`  }
    }
 
    const options = {
        "Esquema": "web",
        "Tabela": "CidadeSelecionadaServiceCenter",
        "Parametros": [
        ]
    
    }

    if (cidadeValue !== 1) {
        options.Parametros.push({ "Nome": "cidadeSelecionada", "Valor": cidadeValue })
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);

    })
}

export const fetchEstadosSelecionados = (estadoValue = null) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}`  }
    }
 
    const options = {
        "Esquema": "web",
        "Tabela": "EstadoSelecionadoServiceCenter",
        "Parametros": [
        ]
    
    }

    if (estadoValue !== 1) {
        options.Parametros.push({ "Nome": "estadoSelecionado", "Valor": estadoValue })
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }

        resolve(ret);

    })
}


export const fetchLocalSelecionado = (estadoValue = null, cidadeValue = null) => {
    const config = {
        headers: { Authorization: `Bearer ${HTTP_TOKEN}`  }
    }
 
    const options = {
        "Esquema": "web",
        "Tabela": "LocalSelecionadoServiceCenter",
        "Parametros": [
            {
                "Nome": "estadoSelecionado", 
                "Valor": estadoValue 
            }
            
        ]    
    }
    
    if (cidadeValue !== 1) {
        options.Parametros.push({ "Nome": "cidadeSelecionada", "Valor": cidadeValue })
    }

    return new Promise((resolve, reject) => {
        const ret = http.post(`Dados/ObterDados`, options, config)

        if (isExpired(ret)) {
            reject(REJECT_MSG);
        }
        resolve(ret);
    })
}